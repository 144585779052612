import { Box, Button, } from '@mui/material';
import '../../../stylesheets/plan-selection.css'
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isDarkMode, themeColors } from '../../utils/constants';
import { Navbar } from '../../components/Navbar';
import Back from '../../components/Reusable/Back';
import { QueryTickers } from './QueryTickers';
import { QueryUsers } from './QueryUsers';
import { QueryDirectives } from './QueryDirectives';
import { QueryParsing } from './QueryParsing';
import { QueryConfigurations } from './QueryConfigurations';

const TAB_OPTIONS = [
  "Parsing", "Tickers", "Directives", "Users", "Configurations"
]

const AdminCRM = (props) => {
  // Eventually we will move this to an admin subdomain and handle there.

  const { appProps } = props
  const { userData , setUserData, loadingUserData } = appProps

  const navigate = useNavigate();

  const [showAdminPage, setShowAdminPage] = useState(false) 

  const [tabOptions, setTabOptions] = useState(TAB_OPTIONS[2])

  // Verify that user is an admin user...
  useEffect(() => {
    // Only navigate away once we have loaded and confirmed not an admin
    if (userData && Object.keys(userData).length > 0 && !loadingUserData) {
      if (userData.is_admin_user) {
        setShowAdminPage(true)
      } else {
        navigate('/404')
      }
    }
  }, [userData, loadingUserData])

  if (!showAdminPage) { // Show something else until it verifies..
    return (
      <Box className="background">
        <Box className="main-container" sx={{height: '100vh', backgroundColor: isDarkMode ? 'black' : 'white'}}>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{width: '100vw', height: '100vh', backgroundColor: themeColors.backgroundColor}}>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
        <Navbar appProps={appProps} headerDescription={'Admin'}/>
        <Back value={false}/>
        
        <Box sx={{width: '100%', padding: '20px 30px',}}>
          <Box sx={{display: 'flex', width: '100%', borderBottom: isDarkMode ? '1px solid white':'1px solid black', marginBottom: '20px'}}>
            {TAB_OPTIONS.map((option) => {
              return <Box sx={{
                fontWeight: '500', fontSize: '18px', color: isDarkMode ? '#F6F6F6' : '#1D1D1F',
                padding: '5px 10px', borderRight: isDarkMode ? '2px solid white':'2px solid black',
                backgroundColor: (tabOptions == option) ? 'gray':'unset',
                '&:hover': { cursor: 'pointer', backgroundColor: 'gray'}
              }} onClick={() => setTabOptions(option)}>{option}</Box>
            })}
          </Box>

          { (tabOptions == "Parsing") && <QueryParsing/>}
          { (tabOptions == "Tickers") && <QueryTickers/>}
          { (tabOptions == "Directives") && <QueryDirectives/>}
          { (tabOptions == "Users") && <QueryUsers/>}
          { (tabOptions == "Configurations") && <QueryConfigurations/>}

          
          {/* <QueryTickers/>
          <QueryTickers/> */}
          {/* Add Search User by ID and by email */}
          {/* Display queried user's data */}
          {/* custom button to "verify an email" */}
          {/* Add number of current admin users */}

        </Box>
      </Box>
    </Box>
  )
}



export default AdminCRM;
