import { useState, useEffect } from "react";
import { isDarkMode } from "../../utils/constants";
import { Box } from "@mui/material";
import SideArrow from "../../../javascript/images/SideArrow.png";
import SideArrowBlack from "../../../javascript/images/SideArrowBlack.png";
import { useRouteLoaderData } from "react-router-dom";
import { handleSharing } from "../../utils/share";
import { userShareExperienceButton } from "../../../javascript/api/user";
import { buttonsMoreButtonsQuestions } from "../../utils/constants";

const ChatButton = (props) => {
  const {
    questionNumber,
    q,
    onQuestionSubmit,
    transitionFlowButtonsAmount,
    preventQuestionInput,
    setPreventQuestionInput,
    isSecondaryExperience,
    secondaryExperienceType,
    userData,
    setUserData,
    setIsSecondaryExperience,
    setModalVisible,
    setShowChatShare,
    handleRegenerateSubmit,
  } = props;

  const [pulsateButton, setPulsateButton] = useState(false);

  let buttonChatClass = isDarkMode
    ? "button-chat-box dark chat-message button"
    : "button-chat-box chat-message button";

  let buttonColor = isDarkMode ? "#252525" : "#E8E8E8";
  let topMargin = "2px";
  if (isSecondaryExperience && secondaryExperienceType === "ask-share") {
    if (questionNumber === 1) {
      topMargin = "50px";
      buttonChatClass = isDarkMode
        ? "button-chat-box-ask-share-1 dark chat-message button"
        : "button-chat-box-ask-share-1 chat-message button";
    } else {
      topMargin = "30px";
      buttonChatClass = isDarkMode
        ? "button-chat-box-ask-share-2 dark chat-message button"
        : "button-chat-box-ask-share-2 chat-message button";
      buttonColor = isDarkMode ? "#252525" : "#F0F5FF";
    }
  }

  useEffect(() => {
    // if (secondaryExperienceType == "new-user") {
    setTimeout(() => {
      setPulsateButton(true);
    }, 3800);
    // }
  }, []);

  return (
    <Box
      sx={{
        opacity: transitionFlowButtonsAmount,
        transition: "opacity .7s",
        marginTop: topMargin,
        zIndex: 1,
      }}
      className="outgoing_container"
    >
      <Box sx={{ padding: "2px 0px 10px 0px" }}>
        <Box
          sx={{
            margin: "auto",
            color: isDarkMode
              ? "#f5f5f5"
              : secondaryExperienceType
              ? "#121212"
              : "#121212",
          }}
        >
          <Box className="bubble_container">
            <Box sx={{ position: "relative" }}>
              <Box
                onClick={() => {
                  if (!preventQuestionInput) {
                    setPreventQuestionInput(true);
                    setTimeout(() => {
                      if (
                        !secondaryExperienceType ||
                        secondaryExperienceType == "new-user"
                      ) {
                        // if (buttonsMoreButtonsQuestions.includes(q)) {
                        //   // If button goes to next button:
                        //   handleRegenerateSubmit(q)
                        //   setPreventQuestionInput(false)
                        // } else {
                        //   // Otherwise:
                        onQuestionSubmit(q);
                        setUserData({
                          ...userData,
                          showAskShareMessage: false,
                        });
                        // }
                      } else if (secondaryExperienceType == "ask-share") {
                        if (questionNumber == 1) {
                          handleSharing(
                            setModalVisible,
                            setShowChatShare,
                            false
                          );
                          setTimeout(() => {
                            setUserData({
                              ...userData,
                              showAskShareMessage: false,
                            });
                          }, 1500);
                          setPreventQuestionInput(false);
                          userShareExperienceButton("share");
                        } else {
                          setUserData({
                            ...userData,
                            showAskShareMessage: false,
                          });
                          setPreventQuestionInput(false);
                          userShareExperienceButton("continue");
                        }
                      }
                    }, 50);
                  }
                }}
                className={buttonChatClass}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: buttonColor,
            
                  animation:
                    questionNumber == 1 &&
                    isSecondaryExperience &&
                    pulsateButton
                      ? isDarkMode
                        ? "pulsate-dark 1.5s 9"
                        : "pulsate 1.5s 9"
                      : "",
                  minWidth: "160px",
                  justifyContent: "center",
                  transition: "opacity 0.2s ease-in-out",
                  "&:hover": {
                    opacity: 0.6, // Reduce opacity on hover
                    cursor: "pointer",
                  },
                }}
              >
                {q}

                {questionNumber == 2 &&
                  secondaryExperienceType == "ask-share" && (
                    <Box
                      sx={{
                        height: "100%",
                        marginLeft: "10px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={isDarkMode ? SideArrow : SideArrowBlack}
                        style={{
                          height: "18px",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatButton;
