import { Box, Button, FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel } from '@mui/material';
import '../../../stylesheets/plan-selection.css'
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isDarkMode, themeColors } from '../../utils/constants';
import { Navbar } from '../../components/Navbar';
import { confirmEmailForUser, getUserFeedbacks, getAllUserFeedbacks, sendUserFeedbackEmailReply, setUserFeedbackResolved, sendUserUnverifiedEmailReply } from './admin_user_actions';
import { timeAgo, Text, formatDateTime } from './utils';
import { POSSIBLE_ERROR_REPORT_RESPONSES, POSSIBLE_EMAIL_VERIFICATION_RESPONSES } from './constants';
import CircularProgress from '@mui/material/CircularProgress';

const QueryUsers = () => {
  const [usersSearched, setUsersSearched] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [userSelectedList, setUserSelectedList] = useState({});
  
  const [userFeedbackData, setUserFeedbackData] = useState({});
  const [feedbackMessage, setFeedbackMessage] = useState({});

  const [unverifiedMessage, setUnverifiedMessage] = useState({});
  const [sentUnverifiedMessage, setSentUnverifiedMessage] = useState({});

  const [feedbackFilter, setFeedbackFilter] = useState("feedback_24-hours"); // Default: Last 24 hours
  const [ loadingEmailSend, setLoadingEmailSend] = useState(false);
  const [ errors, setErrors] = useState([])

  const [includeFeedbackResponded, setIncludeFeedbackResponded] = useState(false);
  const [includeFeedbackResolved, setIncludeFeedbackResolved] = useState(false);



  const triggerSearch = async (query) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({query: `
        {
          admin {
            searchUsers(searchTerm: "${query}") {
              id
              email
              firstName
              lastName

              loginMethod
              emailConfirmed

              accountType
              selectedCountry
              selectedCountryExchange
              stripeCustomerId

              createdAt

              activeSubscription
            }
          }
        }`
      })
    };

    fetch('/graphql', requestOptions)
    .then(response => response.json())
    .then(data => {
      setUsersSearched(data.data.admin.searchUsers)
    })
    .catch(error => console.error('Error fetching data:', error));
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value.length >= 2) { // Consider triggering search when the query length is more than 2 characters
      triggerSearch(event.target.value);
    } else {
      setUsersSearched([])
    }
  };

  
  const addNewUser = (userItem) => {
    setUserSelectedList((prevList) => ({
      ...prevList, 
      [userItem.id]: userItem
    }));
    setSearchQuery("");
    // Now we will grab the user feedbacks
    getUserFeedbacks(userItem.id, setUserFeedbackData)
  };
  
  const removeNewUser = (userItem) => {
    setUserSelectedList((prevList) => {
      const updatedList = { ...prevList };
      delete updatedList[userItem.id]; 
      return updatedList;
    });
  };

  

  let classPlaceholderInput = isDarkMode ? "placeholder-input dark" : "placeholder-input"
  const textString = isDarkMode ? "base-text dark watchlist-text" : "base-text watchlist-text";

  return (
    <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', gap: '15px'}}>

      <Box sx={{display: 'flex', gap: '100px', }}>
        <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', gap: '15px'}}>
          {Text("User Search: (Via email)")}

          <Box sx={{height: '40px', position: 'relative', width: '280px'}}>
          <input
            type="text"
            placeholder={`Search...`}
            value={searchQuery}
            onChange={handleSearchChange}
            onBlur={() => {
              setTimeout(() => {
                setUsersSearched([]); // Timeout needed so that click will go through before it hides.
              }, 150);
            }}
            onFocus={(e) => {
              handleSearchChange(e)
            }}
            className={classPlaceholderInput}
            // style={{ right: '0xp', height: '16px', margin: '0 0 0 0', padding: '8px 16px' }}
            style={{ 
              top: 'unset', left: 'unset', bottom: 'unset', right: 'unset',
              maxWidth: '250px', width: '280px', height: '16px', padding: '8px 16px' 
            }}
          />

          </Box>

          {usersSearched && usersSearched.length > 0 &&
            <Box sx={{zIndex: 2, position: 'absolute', border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', left: '2px', right: '0px', top: '58px', borderRadius: '8px',  
            display: 'flex', flexDirection: 'column', gap: '6px', padding: '0px', }}>
            { usersSearched.map((userItem) => {
              return (
                <Box sx={{
                  border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px', padding: '7px',
                  '&:hover': { cursor: 'pointer', backgroundColor: 'gray',  }
                }}
                onClick={() => addNewUser(userItem)}
                  >
                  <span className={textString} style={{fontSize: '12px'}}>{userItem.email}</span> (<span className={textString} style={{fontSize: '12px'}}>{userItem.firstName} {userItem.lastName}</span>)
                </Box>
              )
            })
            }
            </Box>
          }
        </Box>

        <Box> {Text("OR")} </Box>

        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          {Text("Pool User Set:")}

          <Box sx={{ height: '40px' }}>
            <FormControl sx={{ width: 260 }}>
              <Select
                value={feedbackFilter}
                onChange={(e) => setFeedbackFilter(e.target.value)} // Handle change
                displayEmpty
                sx={{
                  height: '40px',
                  backgroundColor: isDarkMode ? "#131521" : "#F6F6F6",
                  color: isDarkMode ? "#F6F6F6" : "#1D1D1F",
                  "& .MuiSvgIcon-root": {
                    color: isDarkMode ? "#F6F6F6" : "#1D1D1F"
                  },
                  "& fieldset": {
                    borderColor: isDarkMode ? "#F6F6F6" : "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: isDarkMode ? "#A0A0A0" : "#888",
                  }
                }}
              >
                <MenuItem value="feedback_24-hours">User Feedback Last 24 hours</MenuItem>
                <MenuItem value="feedback_48-hours">User Feedback Last 48 hours</MenuItem>
                <MenuItem value="feedback_1-week">User Feedback Last 1 week</MenuItem>
                <MenuItem value="feedback_1-month">User Feedback Last 1 month</MenuItem>
              </Select>
            </FormControl>
          </Box>
          
          <Button variant="contained" className="i_understand_button" sx={{...buttonStyles}}
            onClick={() => {
              // Optional, but I'll wipe the current rows pulled when doing this:
              setUserSelectedList({})
              setUserFeedbackData({})
              if (feedbackFilter.split('_')[0] == "feedback") {
                const timeframe = feedbackFilter.split('_')[1] // will be something like "1-month" passed to the backend
                const conditions = {
                  timeframe: timeframe,
                  includeFeedbackResponded: includeFeedbackResponded,
                  includeFeedbackResolved: includeFeedbackResolved,
                }
                getAllUserFeedbacks(conditions, setUserSelectedList, setUserFeedbackData)
              }
            }}
            >
            <Box className="base-text i_understand_text" sx={submitStyles}>
              Run Query
            </Box>
          </Button>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeFeedbackResponded}
                onChange={(e) => setIncludeFeedbackResponded(e.target.checked)}
                sx={{
                  color: isDarkMode ? "#F6F6F6" : "#1D1D1F",
                  '&.Mui-checked': {
                    color: isDarkMode ? "#90caf9" : "#1976d2", // Light blue in dark mode, default blue in light mode
                  },
                }}
              />
            }
            label={
              <Box sx={{ color: isDarkMode ? "#F6F6F6" : "#1D1D1F", fontSize: "13px", fontWeight: 500 }}>
                Include Feedback Responded
              </Box>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includeFeedbackResolved}
                onChange={(e) => setIncludeFeedbackResolved(e.target.checked)}
                sx={{
                  color: isDarkMode ? "#F6F6F6" : "#1D1D1F",
                  '&.Mui-checked': {
                    color: isDarkMode ? "#90caf9" : "#1976d2", 
                  },
                }}
              />
            }
            label={
              <Box sx={{ color: isDarkMode ? "#F6F6F6" : "#1D1D1F", fontSize: "13px", fontWeight: 500 }}>
                Include Feedback Resolved
              </Box>
            }
          />
        </Box>


        <Box>
          {errors.map((error) => {
            return (
              <Box className="base-text" sx={{color: 'red'}}>
                {error}
              </Box>
            )
          })}
        </Box>
      </Box>

      {Text(`User Data (${Object.keys(userSelectedList).length})`)}

      <Box sx={{position: 'relative', marginRight: '50px',}}>
        {Object.keys(userSelectedList).length > 0 &&
          <Box sx={{ 
          display: 'flex', flexDirection: 'column', gap: '12px', padding: '0px',
          height: '700px',
          maxHeight: '60vh',
          overflowY: 'auto',
          paddingRight: '10px',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(255,255,255,0.1)',
          }
          
           }}>
          { Object.keys(userSelectedList).map((k) => {
            const userItem = userSelectedList[k]

            const curFeedbackReply = feedbackMessage[userItem.id] || ""
            const setCurFeedbackReply = (val) => {
              setFeedbackMessage((prevList) => ({
              ...prevList, 
              ...{[userItem.id]: val}
              }));
            }

            const curUnverifiedReply = unverifiedMessage[userItem.id] || ""
            const setCurUnverifiedReply = (val) => {
              setUnverifiedMessage((prevList) => ({
              ...prevList, 
              ...{[userItem.id]: val}
              }));
            }
            const curSentUnverifiedReply = (sentUnverifiedMessage[userItem.id] == undefined) ? userItem.emailConfirmed : sentUnverifiedMessage[userItem.id]
            const setCurSentUnverifiedReply = (val) => {
              setSentUnverifiedMessage((prevList) => ({
              ...prevList, 
              ...{[userItem.id]: val}
              }));
            }

            return (
              <Box sx={{display: 'flex', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', border: '#F6F6F6', borderRadius: '8px', width: '100%'}}>
                <Box sx={{width: '30%'}}>
                  <Box sx={{
                    marginLeft: '32px', position: 'relative', padding: '8px 8px', }}>
                    <Box sx={{position: 'absolute', left: '-22px', margin: '4px 6px 0px 0px', width: '20px', height: '20px', backgroundColor: 'rgba(255,0,0,.9)', borderRadius: '50%',
                      '&:hover': { cursor: 'pointer' }
                    }} onClick={() => removeNewUser(userItem)}
                    >
                      <Box sx={{width: '11px', height: '2px', backgroundColor: 'white', position: 'absolute', left: '4px', top: '9px', borderRadius: '1px'}}/>
                    </Box>

                    { Object.keys(userItem).map((k) => {
                      return (
                        <Box className={textString} style={{fontSize: '13px', lineHeight: '166%', }}>
                          {k}:&nbsp;
                          { (k == "emailConfirmed" || k == "activeSubscription") ?
                            <span className={textString} style={{fontSize: '13px', lineHeight: '166%', color: userItem[k] ? "green":"#DAA520" }}>
                              {`${userItem[k]}`}
                            </span>
                          :
                            <>{`${userItem[k]}`}</>
                          }
                        </Box>
                      )
                    })}
                  </Box>
                </Box>

                <Box sx={{width: '35%', padding: '8px 8px'}}>
                  {Text("Latest Feedback: (limit 5)")}
                  <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px', }}>
                    { userFeedbackData[userItem.id] && userFeedbackData[userItem.id].map((feedback) => {
                      let respondedString = ""
                      if (feedback.responseSentAt) {
                        respondedString = `Responded ${timeAgo(feedback.responseSentAt)}`
                      }
                      let resolvedString = ""
                      if (feedback.errorResolvedAt) {
                        resolvedString = `Resolved ${timeAgo(feedback.errorResolvedAt)}`
                      }
                      
                      // We won't show feedbacks that are cancels with no note.
                      let feedbackMessage = feedback.feedback;
                      // Note, in the backend we already remove from the set any user cancels without feedback (and the reactivated subscription which creates a row as well)
                      feedbackMessage = feedbackMessage.replace('** ERROR REPORT **\n', '')

                      return (
                        <Box className={textString} style={{fontSize: '13px', lineHeight: '166%', }}>
                          {/* <p>{`${feedback.createdAt}`}</p> */}
                          <i style={{fontSize: '11px'}}>{`Sent ${timeAgo(feedback.createdAt)}`}</i>&nbsp;<b style={{fontSize: '11px', color: '#DAA520'}}> {respondedString}</b>&nbsp;<b style={{fontSize: '11px', color: 'green'}}> {resolvedString}</b><br></br>
                          "{feedbackMessage}"
                        </Box>
                      )
                    })}
                  </Box>
                </Box>

                <Box sx={{width: '35%', display: 'flex', flexDirection: 'column', gap: '20px', padding: '8px 8px'}}>
                  {Text("Actions:")}

                    { (!userItem.emailConfirmed) && 
                      <Button variant="contained" className="i_understand_button" sx={buttonStyles}
                        onClick={() => {
                          confirmEmailForUser(userItem.id, setUserSelectedList)
                        }}
                        >
                        <Box className="base-text i_understand_text" sx={submitStyles}>
                          Confirm Email for User
                        </Box>
                      </Button>
                    }

                    { (!curSentUnverifiedReply) && 
                      <Box sx={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                        <Button variant="contained" className="i_understand_button" sx={buttonStyles}
                          onClick={() => {
                            const randomIndex = Math.floor(Math.random() * POSSIBLE_EMAIL_VERIFICATION_RESPONSES.length);
                            const selectedMessage = POSSIBLE_EMAIL_VERIFICATION_RESPONSES[randomIndex].trim();
                            const formattedName = userItem.firstName.trim().charAt(0).toUpperCase() + userItem.firstName.trim().slice(1).toLowerCase();
                            setCurUnverifiedReply(selectedMessage.replace("{FIRST_NAME}", formattedName))
                          }}
                          >
                          <Box className="base-text i_understand_text" sx={submitStyles}>
                            Email Unverified User
                          </Box>
                        </Button>
                        <Box className="base-text i_understand_text" sx={{fontSize: '12px', color: isDarkMode ? "#F6F6F6" : "#1D1D1F"}}>(Send email before confirming)</Box>
                        { (curFeedbackReply != "") && <Close setCurUnverifiedReply={setCurUnverifiedReply} setCurFeedbackReply={setCurFeedbackReply} setErrors={setErrors} setLoadingEmailSend={setLoadingEmailSend}/>}
                      </Box>
                    }


                    { (curUnverifiedReply != "" && !curSentUnverifiedReply) && (
                      <Box sx={{ marginTop: "0px", width: '100%'}}>
                        <textarea
                          value={curUnverifiedReply}
                          onChange={(e) => setCurUnverifiedReply(e.target.value)} // Allow editing
                          style={{
                            width: "90%",
                            minHeight: "150px",
                            padding: "8px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            marginBottom: '10px',
                            backgroundColor: isDarkMode ? "#131521" : "#F6F6F6",
                            color: isDarkMode ? "#F6F6F6" : "#1D1D1F"
                          }}
                        />
                        <Button variant="contained" className="i_understand_button" sx={{...buttonStyles, width: '120px',}}
                          onClick={() => {
                            setLoadingEmailSend(true)
                            sendUserUnverifiedEmailReply(userItem.id, curUnverifiedReply, setLoadingEmailSend, setCurUnverifiedReply, setCurSentUnverifiedReply, setErrors)
                          }}
                          >
                          <Box className="base-text i_understand_text" sx={submitStyles}>
                            { (loadingEmailSend) ?
                              <CircularProgress size={24} color="inherit" />
                            :
                              "Send Email"
                            }
                          </Box>
                        </Button>
                      </Box>
                    )}

                    { (userFeedbackData[userItem.id] && userFeedbackData[userItem.id][0] && !userFeedbackData[userItem.id][0].responseSentAt) && 
                      <Box sx={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                      <Button variant="contained" className="i_understand_button" sx={buttonStyles}
                        onClick={() => {
                          const randomIndex = Math.floor(Math.random() * POSSIBLE_ERROR_REPORT_RESPONSES.length);
                          const selectedMessage = POSSIBLE_ERROR_REPORT_RESPONSES[randomIndex].trim();
                          const formattedName = userItem.firstName.trim().charAt(0).toUpperCase() + userItem.firstName.trim().slice(1).toLowerCase();
                          setCurFeedbackReply(selectedMessage.replace("{FIRST_NAME}", formattedName))
                        }}
                        >
                        <Box className="base-text i_understand_text" sx={submitStyles}>
                          Reply to Feedback
                        </Box>
                      </Button>
                      
                      { (curFeedbackReply != "") && <Close setCurUnverifiedReply={setCurUnverifiedReply} setCurFeedbackReply={setCurFeedbackReply} setErrors={setErrors} setLoadingEmailSend={setLoadingEmailSend}/>}
                      
                      </Box>
                    }
                    

                    { (curFeedbackReply != "") && (
                      <Box sx={{ marginTop: "0px", width: '100%'}}>
                        <textarea
                          value={curFeedbackReply}
                          onChange={(e) => setCurFeedbackReply(e.target.value)} // Allow editing
                          style={{
                            width: "90%",
                            minHeight: "130px",
                            padding: "8px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            marginBottom: '10px',
                            backgroundColor: isDarkMode ? "#131521" : "#F6F6F6",
                            color: isDarkMode ? "#F6F6F6" : "#1D1D1F"
                          }}
                        />
                        <Button variant="contained" className="i_understand_button" sx={{...buttonStyles, width: '120px',}}
                          onClick={() => {
                            setLoadingEmailSend(true)
                            const userFeedbackId = userFeedbackData[userItem.id][0].id
                            sendUserFeedbackEmailReply(userItem.id, userFeedbackId, curFeedbackReply, setLoadingEmailSend, setCurFeedbackReply, setUserFeedbackData, setErrors)
                          }}
                          >
                          <Box className="base-text i_understand_text" sx={submitStyles}>
                            { (loadingEmailSend) ?
                              <CircularProgress size={24} color="inherit" />
                            :
                              "Send Email"
                            }
                          </Box>
                        </Button>
                      </Box>
                    )}


                    { (curFeedbackReply == "" && userFeedbackData[userItem.id] && userFeedbackData[userItem.id][0] && !userFeedbackData[userItem.id][0].errorResolvedAt) && 
                      <Button variant="contained" className="i_understand_button" sx={buttonStyles}
                        onClick={() => {
                          const userFeedbackId = userFeedbackData[userItem.id][0].id
                          setUserFeedbackResolved(userItem.id, userFeedbackId, setUserFeedbackData, setErrors)
                        }}
                        >
                        <Box className="base-text i_understand_text" sx={submitStyles}>
                          Mark Feedback Resolved
                        </Box>
                      </Button>
                    }


                </Box>



              </Box>
            )
          })
          }
          </Box>
        }
      </Box>


      

    </Box>
  )

}


const buttonStyles = {
  width: '200px',
  height: '32px',
  textTransform: 'none',
  borderRadius: '24px',
  background: themeColors.buttonColor,
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
  marginTop: '0px',
}


const submitStyles = {
  display: 'flex', fontWeight: '400', alignItems: 'center',
  justifyContent: 'center', textAlign: 'center', marginTop: '0px', color: '#F2F2F2', fontSize: '12px',
}
const Close = ( { setCurUnverifiedReply, setCurFeedbackReply, setErrors, setLoadingEmailSend }) => {
  return <Box className="base-text" sx={{color: isDarkMode ? 'white':'black', fontWeight: '500', fontSize: '13px',
    '&:hover': { cursor: 'pointer'  }
  }} onClick={() => {
    setCurUnverifiedReply('')
    setCurFeedbackReply('')
    setErrors([])
    setLoadingEmailSend(false)
  }}
  >Cancel</Box>
}

export { QueryUsers } 