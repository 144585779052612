import React, { useEffect } from "react";
import { Box } from "@mui/material"
import { useState } from "react";

import { isDarkMode } from "../../utils/constants"
import { handleSharing } from "../../utils/share";
import RefreshDataDark from '../../images/RefreshDataDark.png'
import ShareButtonDark from '../../images/ShareButtonDark.png'
import RefreshData from '../../images/RefreshData.png'
import ShareButton from '../../images/ShareButton.png'
import WatchButtonDark from '../../images/WatchButtonDark.png'
import WatchButton from '../../images/WatchButton.png'
import PaywallSelectCheck from '../../images/PaywallSelectCheck.png'
import WatchlistUpdate from '../../images/WatchlistUpdate.png'
import ReportError from '../../images/ReportError.png'
import ReportErrorDark from '../../images/ReportErrorDark.png'
import { updateUserWatchlist } from '../../api/user'
import { ReportModal } from '../ReportModal';
import { watchlistPaywallModalMessage, get_max_watchlist_items } from "../../utils/constants";
import InciteLogoOnly from '../../../../public/InciteLogoOnly.png'
import { useNavigate } from 'react-router-dom';
import { isMobileBrowser } from "../../utils/user_agent";

const DisplayData = (props) => {
  const { 
    appProps,
    messageThread, isLastMessage, loadingResponse, showAddWatchlist, displayUpdatedInfo, preventQuestionInput, setPreventQuestionInput, secondaryExperienceType, onQuestionSubmit,
    setModalVisible, setShowChatShare, isLoggedIn, countOfWatchlistTicker, setShowAddWatchlist,
    freeUnlimitedOnly, hasShownFreeUnlimitedMessage,
  } = props

  const { setShowWatchlistBlueDot, setShowWatchlistFlicker, userData, setUserData } = appProps

  const [showReportModal, setShowReportModal] = useState(false)
  const [showAddingWatchlist, setShowAddingWatchlist] = useState(false)
  const [showAddedatchlist, setShowAddedatchlist] = useState(false)
  const [watchlistCountError, setWatchlistCountError] = useState(false)

  const navigate = useNavigate()

  const isMobile = isMobileBrowser();


  const isAfterHours = !!displayUpdatedInfo.closePrice
  const is24hrMarket = (messageThread.assetType == "cryptos" || messageThread.assetType == "forex")

  // Log information in the browser so easier to debug.
  // useEffect(() => {
  //   if (displayUpdatedInfo) {
  //     console.log("displayUpdatedInfo", displayUpdatedInfo)
  //   }
  // }, [displayUpdatedInfo])

  return (
    <Box className="answer-description-text" sx={{
      marginTop: '7px',
      color: isDarkMode ? '#848484' : '#888888',//'#B7B7B7',
      display: 'flex', alignItems: 'stretch', justifyContent: 'space-between',
      }}>
      <Box sx={{margin: 'auto auto auto 0px'}}>
        {messageThread.tickerDescription && (messageThread.tickerDescription.length < 2
        ?
        <Box sx={{display: 'flex', alignItems: 'center'}}>
        { !isDarkMode && messageThread.logoUrls.length > 0 &&
          <div style={{height: '32px', marginRight: '8px'}}>
            { messageThread.logoUrls.map((logoUrl, idx) => {
              return (
                <img src={`${logoUrl}`} style={{maxHeight: '100%', mixBlendMode: "multiply"}}/>
              )
            })}
          </div>
        }
        <Box>
        {  !freeUnlimitedOnly && messageThread.tickerDescription.map((td, idx) => {
          return (
          (idx == messageThread.tickerDescription.length-1) ? 
            displayUpdatedInfo.currentPrice ? td + `  (${messageThread.ticker})` : td
          :
            td+" | "
          )
        })}
        { (displayUpdatedInfo.currentPriceUpdatedAt && !freeUnlimitedOnly) &&
          <>
            { displayUpdatedInfo.currentPrice &&
              <>
                { (isAfterHours && !is24hrMarket) ?
                  <>
                  <Box sx={{marginTop: '4px'}}>
                    {`Close Price: `}{`$${displayUpdatedInfo.closePrice}`}
                    { displayUpdatedInfo.pricePctChange1d != null &&
                      <span style={{color: parseFloat(displayUpdatedInfo.pricePctChange1d) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(displayUpdatedInfo.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                        {` (${parseFloat(displayUpdatedInfo.pricePctChange1d) >= 0 ? '+':''}${Math.round(10000*parseFloat(displayUpdatedInfo.pricePctChange1d))/100}%)`}
                      </span>
                    }
                  </Box>
                  { (displayUpdatedInfo.pricePctChangeAh != null) &&
                  <>
                    {`After hours: `}{`$${displayUpdatedInfo.currentPrice}`} <span style={{color: parseFloat(displayUpdatedInfo.pricePctChangeAh) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(displayUpdatedInfo.pricePctChangeAh) >= 0 ? 1.0 : 0.75}}>
                      {` (${parseFloat(displayUpdatedInfo.pricePctChangeAh) >= 0 ? '+':''}${Math.round(10000*parseFloat(displayUpdatedInfo.pricePctChangeAh))/100}%)`}
                    </span>
                  </>
                  }
                  </>
                :
                  <Box sx={{marginTop: '4px'}}>
                    {`Price: `}<b>{`$${displayUpdatedInfo.currentPrice}`} </b> 
                    { (displayUpdatedInfo.pricePctChange1d != null && displayUpdatedInfo.pricePctChange1d != "f") &&
                      <>
                        { false ?//is24hrMarket ?
                          <span style={{color: parseFloat(displayUpdatedInfo.pricePctChange24hr) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(displayUpdatedInfo.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                          {/* {` (${parseFloat(displayUpdatedInfo.pricePctChange24hr) >= 0 ? '+':''}${Math.round(10000*parseFloat(displayUpdatedInfo.pricePctChange24hr))/100}% last 24hrs)`} */}
                          {/*  NOTE, making life easier now to align with the live charts; no longer showing last 24 hours, just the price change from prior bar. */}
                          {` (${parseFloat(displayUpdatedInfo.pricePctChange24hr) >= 0 ? '+':''}${Math.round(10000*parseFloat(displayUpdatedInfo.pricePctChange24hr))/100}%)`}
                          </span>
                        :
                          <span style={{color: parseFloat(displayUpdatedInfo.pricePctChange1d) >= 0 ? '#188038' : '#E34234', opacity: parseFloat(displayUpdatedInfo.pricePctChange1d) >= 0 ? 1.0 : 0.75}}>
                            {` (${parseFloat(displayUpdatedInfo.pricePctChange1d) >= 0 ? '+':''}${Math.round(10000*parseFloat(displayUpdatedInfo.pricePctChange1d))/100}%)`}
                          </span>
                        }
                      </>
                    }
                  </Box>
                }
                { isLastMessage && 
                  <Box sx={{marginTop: '4px'}}>
                    Last updated: {displayUpdatedInfo.currentPriceUpdatedAt}
                  </Box>
                }
              </>
            }
          </>
        }
        </Box>
        </Box>
        :
        <Box>
          {`${messageThread.tickerDescription.length} investments analyzed`}
          { displayUpdatedInfo.currentPriceUpdatedAt &&
            <Box sx={{marginTop: '4px'}}>
              Last updated: {displayUpdatedInfo.currentPriceUpdatedAt}
            </Box>
          }
        </Box>
      
        )}
      </Box>
      { (isLastMessage && !loadingResponse) &&
        <Box sx={{ marginTop: '10px' }}>
          <Box sx={{display: 'flex', gap: '20px'}}>
            { (messageThread.tickerDescription && messageThread.tickerDescription.length > 0) &&
            <Box sx={{display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
              onClick={() => {
                handleSharing(setModalVisible, setShowChatShare, true)
              }}
            >
              <img src={isDarkMode ? ShareButtonDark : ShareButton} style={{maxHeight: '16px'}} /> Share
            </Box>
            }
            { showAddWatchlist && <>
              { messageThread.answeredTickerIds.length > 0 &&
                <Box sx={{position: 'relative', display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
                  onClick={() => {
                    if (isLoggedIn) {
                      if (userData && (userData.has_active_subscription || userData.is_admin_user)) {  
                        const intIds = messageThread.answeredTickerIds.map(v => parseInt(v))
                        if (countOfWatchlistTicker + intIds.length > get_max_watchlist_items(userData)) {
                          setWatchlistCountError(true)
                          setTimeout(() => {
                            setWatchlistCountError(false)
                          }, 4000)
                        } else {
                          setWatchlistCountError(false)
                          updateUserWatchlist(intIds, null, appProps.setWatchlistData)
                          setShowAddingWatchlist(true)
                          setShowWatchlistBlueDot(true)
                          setShowWatchlistFlicker(true)
                          setTimeout(() => {
                            setShowAddingWatchlist(false)
                            setShowAddedatchlist(true)
                          }, 1300)
                          setTimeout(() => {
                            setShowAddWatchlist(false)
                          }, 2600)
                          setTimeout(() => {
                            setShowWatchlistFlicker(false)
                            setShowAddedatchlist(false)
                          }, 5000)
                        } 
                      } else {
                        // Show paywall with custom message
                        // appProps.setPaywallMessage(watchlistPaywallModalMessage)
                        // appProps.setShowPaywallModal(true)
                        navigate('/plan-selection')
                      }

                    } else {
                      appProps.setShowSignUpModal(true)
                    }
                  }}
                >
                  { showAddingWatchlist ? 
                    <> <img src={WatchlistUpdate} style={{maxHeight: '16px'}} className={"rotate-animation"} /> Watch </>
                    :
                    <> <img src={showAddedatchlist ? PaywallSelectCheck : (isDarkMode ? WatchButtonDark : WatchButton)} style={{maxHeight: '16px'}} /> Watch </>
                  }
                  { watchlistCountError &&
                    <Box sx={{position: 'absolute', border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', left: '-86px', width: '272px', top: isMobile ? '-50px':'25px', borderRadius: '8px', display: 'flex', flexDirection: 'column', gap: '6px', padding: '0px', }}>
                      <Box sx={{ border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px', padding: '7px' }}>
                        <span className={isDarkMode ? "base-text dark" : "base-text"} style={{fontSize: '12px'}}>
                        {`You can only have ${get_max_watchlist_items(userData)} items at a time in your watchlist. Please remove to add more.`}
                        </span>
                      </Box>
                    </Box>
                  }
                </Box>
              }
              </>
            }
            { (messageThread.tickerDescription && messageThread.tickerDescription.length > 0) &&
            <Box sx={{display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
              onClick={() => {
                if (!preventQuestionInput) {
                  setPreventQuestionInput(true)
                  setTimeout(() => {
                    if (!secondaryExperienceType || secondaryExperienceType == "new-user") {
                      onQuestionSubmit("Update")
                    } else if (secondaryExperienceType == "ask-share") {
                      if (questionNumber == 1) {
                        handleSharing(setModalVisible, setShowChatShare, false)
                        setTimeout(() => {
                          setUserData({...userData, showAskShareMessage: false})
                        }, 1500)
                        setPreventQuestionInput(false)
                        userShareExperienceButton("share")
                      } else {
                        setUserData({...userData, showAskShareMessage: false})
                        setPreventQuestionInput(false)
                        userShareExperienceButton("continue")
                      }
                    }
                  }, 50)
                }
              }}
            >
              <img src={isDarkMode ? RefreshDataDark : RefreshData} style={{marginTop: '1px', maxHeight: '16px'}} /> Update
            </Box>
            }
            <Box sx={{display: 'flex', alignItems: 'center', gap: '5px', color: isDarkMode ? '#fff':'#000', fontSize: '13px', '&:hover': {cursor: 'pointer'}}}
              onClick={() => {
                setShowReportModal(true)
              }}
            >
              <img src={isDarkMode ? ReportErrorDark : ReportError} style={{marginTop: '1px', maxHeight: '16px'}} /> Report
            </Box>

            { showReportModal &&
              <ReportModal setShowReportModal={setShowReportModal}/>
            }

          </Box>
          {
          freeUnlimitedOnly && (
            hasShownFreeUnlimitedMessage ? (
              <Box sx={{ marginTop: '20px' }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '32px',
                  width: '86px',
                  borderRadius: '26px',
                  color: 'white',//isDarkMode ? 'black' : '#f5f5f5',
                  backgroundColor: '#5390E6',//isDarkMode ? '#f5f5f5' : 'black',
                  '&:hover': { cursor: 'pointer' }
                }} onClick={() => {
                  if (userData && userData.user_account_info && Object.keys(userData.user_account_info).length > 0) {
                    navigate('/plan-selection');
                  } else {
                    navigate('/register');
                  }
                }}>
                  Upgrade
                </Box>
              </Box>
            ) : (
              <Box sx={{
                marginTop: '18px',
                maxWidth: '250px',
                border: '1px solid gray',
                borderRadius: '10px',
                padding: '16px',
              }}>
                <Box className="base-text" style={{ color: isDarkMode ? 'white' : 'black', fontSize: '12px', fontWeight: 600, marginBottom: '10px', }}>
                  You have 2 messages remaining today.
                </Box>
                <Box className="base-text" style={{ color: isDarkMode ? 'white' : 'black', fontSize: '12px', fontWeight: 400, }}>
                  Limit resets in 24 hours.
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '18px' }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '32px',
                    width: '178px',
                    borderRadius: '26px',
                    color: 'white',//isDarkMode ? 'black' : 'white',
                    backgroundColor: '#5390E6',//isDarkMode ? 'white' : 'black',
                    '&:hover': { cursor: 'pointer' }
                  }} onClick={() => {
                    if (userData && userData.user_account_info && Object.keys(userData.user_account_info).length > 0) {
                      navigate('/plan-selection');
                    } else {
                      navigate('/register');
                    }
                  }}>
                    Learn More & Upgrade
                  </Box>
                </Box>
              </Box>
            )
          )
        }
        </Box>
      }
    </Box>
  )
}

export default DisplayData
