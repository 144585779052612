import { Box, } from '@mui/material';
import '../../../stylesheets/plan-selection.css'
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { timeAgo, Text, formatDateTime } from './utils';
import { isDarkMode, themeColors } from '../../utils/constants';
import { Navbar } from '../../components/Navbar';

const QueryTickers = () => {
  const [tickersSearched, setTickersSearched] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [tickerSelectedList, setTickerSelectedList] = useState([]);

  const triggerSearch = async (query) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({query: `
        {
          admin {
            searchTickers(searchTerm: "${query}") {
              id
              ticker
              assetType
              name
              currentPrice
              marketCap
              sectors
              exchangeCountry
              exchange
              isEtf
              coinApiSymbolId
            }
          }
        }`
      })
    };

    fetch('/graphql', requestOptions)
    .then(response => response.json())
    .then(data => {
      console.log("data", data)
      setTickersSearched(data.data.admin.searchTickers)
    })
    .catch(error => console.error('Error fetching data:', error));
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value.length >= 2) { // Consider triggering search when the query length is more than 2 characters
      triggerSearch(event.target.value);
    } else {
      setTickersSearched([])
    }
  };

  const addNewTicker = (ticker_item) => {
    setTickerSelectedList((prevList) => [...prevList, ticker_item]);
    setSearchQuery("")
  }

  const removeNewTicker = (ticker_item) => {
    setTickerSelectedList((prevList) => prevList.filter(item => item !== ticker_item));
  };
  

  let classPlaceholderInput = isDarkMode ? "placeholder-input dark" : "placeholder-input"
  const textString = isDarkMode ? "base-text dark watchlist-text" : "base-text watchlist-text";

  console.log("tickersSearched?", tickersSearched)
  console.log("tickerSelectedList?", tickerSelectedList)
  return (
    <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', gap: '15px'}}>

      {Text("TICKER SEARCH:")}

      <Box sx={{height: '40px'}}>
      <input
        type="text"
        placeholder={`Search...`}
        value={searchQuery}
        onChange={handleSearchChange}
        onBlur={() => {
          setTimeout(() => {
            setTickersSearched([]); // Timeout needed so that click will go through before it hides.
          }, 150);
        }}
        onFocus={(e) => {
          handleSearchChange(e)
        }}
        className={classPlaceholderInput}
        // style={{ right: '0xp', height: '16px', margin: '0 0 0 0', padding: '8px 16px' }}
        style={{ 
          top: 'unset', left: 'unset', bottom: 'unset', right: 'unset',
          width: '300px', height: '16px', padding: '8px 16px' 
        }}
      />
      </Box>

      {tickersSearched && tickersSearched.length > 0 &&
        <Box sx={{zIndex: 2, position: 'absolute', border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', left: '2px', right: '2px', top: '58px', borderRadius: '8px',  
        display: 'flex', flexDirection: 'column', gap: '6px', padding: '0px', }}>
        { tickersSearched.map((tickerItem) => {
          return (
            <Box sx={{
              border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px', padding: '7px',
              '&:hover': { cursor: 'pointer', backgroundColor: 'gray',  }
            }}
            onClick={() => addNewTicker(tickerItem)}
              >
              <span className={textString} style={{fontSize: '12px'}}>{tickerItem.name}</span> (<span className={textString} style={{fontSize: '12px'}}>{tickerItem.ticker}</span>)
            </Box>
          )
        })
        }
        </Box>
      }

      {Text("Tickers Selected")}

      <Box sx={{position: 'relative'}}>
        {tickerSelectedList && tickerSelectedList.length > 0 &&
          <Box sx={{border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px',  
          display: 'flex', flexDirection: 'column', gap: '6px', padding: '0px', }}>
          { tickerSelectedList.map((tickerItem) => {
            return (
              <Box sx={{
                display: 'flex', alignItems: 'center',
                border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px', padding: '7px',
              }}>
                <span>
                  <Box sx={{margin: '4px 6px 0px 0px', width: '20px', height: '20px', backgroundColor: 'rgba(255,0,0,.9)', borderRadius: '50%', position: 'relative',
                    '&:hover': { cursor: 'pointer' }
                  }} onClick={() => removeNewTicker(tickerItem)}
                  >
                    <Box sx={{width: '11px', height: '2px', backgroundColor: 'white', position: 'absolute', left: '4px', top: '9px', borderRadius: '1px'}}/>
                  </Box>
                  </span>
                <span className={textString} style={{fontSize: '12px'}}>{tickerItem.name}</span> (<span className={textString} style={{fontSize: '12px'}}>{tickerItem.ticker}</span>)
              </Box>
            )
          })
          }
          </Box>
        }
      </Box>

      {Text("... Link to Directives tab Insert tickers into directives")}
      

    </Box>
  )

}

export { QueryTickers } 