import { Box, TextField, Button, FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel } from '@mui/material';
import '../../../stylesheets/plan-selection.css'
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isDarkMode, themeColors } from '../../utils/constants';
import { Navbar } from '../../components/Navbar';
import { confirmEmailForUser, getUserFeedbacks, getAllUserFeedbacks, sendUserFeedbackEmailReply, setUserFeedbackResolved, sendUserUnverifiedEmailReply } from './admin_user_actions';
import { timeAgo, Text, formatDateTime } from './utils';
import { POSSIBLE_ERROR_REPORT_RESPONSES, POSSIBLE_EMAIL_VERIFICATION_RESPONSES } from './constants';
import CircularProgress from '@mui/material/CircularProgress';

const QueryConfigurations = () => {

  const [updateDisabled, setUpdateDisabled] = useState(true)
  return (
    <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: '15px', maxWidth: '400px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {Text("HOURS_CHECK_LAST_MESSGES:")}
        <TextField size="small" fullWidth />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {Text("DAILY_MESSAGES_BEFORE_SOFT_PAYWALL:")}
        <TextField size="small" fullWidth />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {Text("DAILY_MESSAGES_BEFORE_PAYWALL:")}
        <TextField size="small" fullWidth />
      </Box>



      <Button disabled={updateDisabled} variant="contained" className="i_understand_button" sx={{...buttonStyles}}
        onClick={() => {
          // Optional, but I'll wipe the current rows pulled when doing this:
          setUserSelectedList({})
          setUserFeedbackData({})
          if (feedbackFilter.split('_')[0] == "feedback") {
            const timeframe = feedbackFilter.split('_')[1] // will be something like "1-month" passed to the backend
            const conditions = {
              timeframe: timeframe,
              includeFeedbackResponded: includeFeedbackResponded,
              includeFeedbackResolved: includeFeedbackResolved,
            }
            getAllUserFeedbacks(conditions, setUserSelectedList, setUserFeedbackData)
          }
        }}
        >
        <Box className="base-text i_understand_text" sx={submitStyles}>
          Update
        </Box>
      </Button>


    </Box>
  );
}

const buttonStyles = {
  width: '200px',
  height: '32px',
  textTransform: 'none',
  borderRadius: '24px',
  background: themeColors.buttonColor,
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
  marginTop: '0px',
}

const submitStyles = {
  display: 'flex', fontWeight: '400', alignItems: 'center',
  justifyContent: 'center', textAlign: 'center', marginTop: '0px', color: '#F2F2F2', fontSize: '12px',
}




export { QueryConfigurations } 
