import { Box } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import favicon from '../../assets/images/favicon/android-chrome-512x512.png'
import { useState } from 'react';
import { themeColors, isDarkMode } from './constants';
import '../../stylesheets/navbar.css'
import '../../stylesheets/index.css'
import { userShared } from '../api/user';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';

const inlineStyles = {
  modal: {
    zIndex: 99,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modalContent: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(115,115,115,1.0)',
    padding: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    width: '280px',
    textAlign: 'left',
    borderRadius: '8px',
  },
  close: {
    position: 'absolute',
    top: '8px',
    right: '15px',
    cursor: 'pointer',
    fontSize: '30px',
  },
  
};

const Button = ({ label, onClick }) => {
  const [hover, setHover] = useState(false);

  const baseStyle = {
    borderRadius: '4px',
    textAlign: 'left',
    backgroundColor: 'rgba(115, 115, 115, 0.9)',
    cursor: 'pointer',
    boxShadow: 'none',
    border: 'none',
  };

  const hoverStyle = {
    backgroundColor: 'rgba(100, 100, 100, 0.9)',
  };

  return (
    <button
      style={hover ? { ...baseStyle, ...hoverStyle } : baseStyle}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {label}
    </button>
  );
};


const ShareModal = ({modalVisible, setModalVisible}) => {
  // const url = window.location.href;
  const url = document.location.origin

  // NEED TO FIX
  // let shareHash = ""
  // if (shareAnswer) { 
  //   shareHash = uuidv4()
  //   url = url + `?share=${shareHash}`
  // }

  const shareTo = (platform) => {
    let shareUrl = '';
    
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;
        break;
      case 'iMessage':
        shareUrl = `sms:&body=${encodeURIComponent("I asked Incite: " + url)}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, '_blank');
  };

  const copyToClipboard = () => {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = window.location.href;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    alert('URL copied to clipboard');
  };
  return (
    <Box>
      {modalVisible && (
        <div style={inlineStyles.modal}>
          <div style={inlineStyles.modalContent}>
            <span style={inlineStyles.close} onClick={() => setModalVisible(false)}>
              &times;
            </span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* asdf <img src={favicon} alt="favicon" style={{ marginRight: '10px', width: '24px', height: '24px' }} /> */}
              <img src={favicon} alt="favicon" style={{ marginRight: '10px', width: '46px', height: '46px' }}/>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <h3 style={{marginBottom: '0'}}>Share Incite</h3>
                <h4 style={{marginTop: '0'}}>inciteai.com</h4>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
            <Button label="Messages" onClick={() => shareTo('iMessage')} />
            <Button label="Facebook" onClick={() => shareTo('facebook')} />
            <Button label="Twitter" onClick={() => shareTo('twitter')} />
            <Button label="LinkedIn" onClick={() => shareTo('linkedin')} />
            {/* <Button label="Copy URL" onClick={copyToClipboard} /> */}
            </div>
          </div>
        </div>
      )}
    </Box>
  )
}

const handleSharing = (setModalVisible, setShowChatShare, shareAnswer) => {


  //const canonical = document.querySelector("link[rel=canonical]");
  //let url = canonical ? canonical.href : document.location.href;
  const title = "AI-Powered Decision-Making | Real-Time Insights | Stock Market & Crypto | Free";//"Incite"
  const text= " "//""//"Check out Incite. Ask anythign about stocks. What to buy when to sell."
  // let url = 'https://app.inciteai.com'//document.location.origin
  let url = document.location.origin
  let shareHash = ""
  if (shareAnswer) { 
    shareHash = uuidv4()
    url = url + `?share=${shareHash}`
  }
  // url = "https://app.inciteai.com"
  const shareDetails = { url, title, text };
  
  if (navigator.share) {
    userShared(shareAnswer, shareHash, false)
    setTimeout(() => { // set a small timeout to make sure db can create for meta tags!
    try {
      navigator.share(shareDetails)
      .then(() => {
        userShared(shareAnswer, shareHash, true) //  here we actually mark that it is shared
        .then((resp) => {
          if (resp == "ERROR") {
            console.log("Error!", resp)
          } else {
            // console.log("RESPP?", resp)
            setShowChatShare(false)
          }
        })

      })
      .catch(error => {
        if (error.name === "AbortError") {
          console.log("User aborted the share operation");
        } else {
          console.log(`Oops! I couldn't share to the world because: ${error}`);
        }
      });
    } catch (error) {
      console.log(`Oops! I couldn't share to the world because: ${error}`);
    }
    }, 200)
  } else {
    // Fallback code
    console.log("Web share is currently not supported on this browser. Please provide a callback");
    console.log("will show custom modal!")
    setModalVisible(true)
  }
};

// const ShareButton = ({ setModalVisible, setShowChatShare }) => {
//   return (
//     <MuiButton
//       variant="outlined"
//       className="button-share-navbar"
//       sx={{
//         boxShadow: 0,
//         border: `1px solid ${themeColors.linkColor} 28%`,
//         }}
//         onClick={() => handleSharing(setModalVisible, setShowChatShare)}
//         type="submit"
//         >
//         <Box sx={{
//           display: 'flex',
//           fontWeight: '500', alignItems: 'center',
//           justifyContent: 'center',
//           textAlign: 'center',
//           marginTop: '1px',
//           padding: 0,
//           // color: '#F2F2F2',
//           color: themeColors.linkColor,
//           fontSize: '10px',
//           textTransform: 'none',
//         }}>
//         Share
//       </Box>
//     </MuiButton>
//   )
// }

const ShareButtonChat = ({ setShowChatShare, transitionFlowButtonsAmount, setModalVisible }) => {
  let buttonChatClass = isDarkMode ? 
    "button-chat-box dark chat-message button"
    :
    "button-chat-box chat-message button"

  let buttonColor
  if (!isDarkMode) {
    buttonColor = 'linear-gradient(180deg, #295AD7 0%, rgba(41, 58, 215, 0.69) 100%)'
  } else {
    buttonColor = 'linear-gradient(180deg, #2971D7 0%, rgba(26, 165, 195, 0.69) 100%)'
  }

  return (
    <Box sx={{
        opacity: transitionFlowButtonsAmount,
        transition: 'opacity .7s',
        marginTop: '2px',
        // backgroundColor: themeColors.buttonColor//isDarkMode ? themeColors.chatBoxColor : '',
      }} 
      className="outgoing_container">
      <Box sx={{
        padding: '2px 0px 10px 0px',
        // backgroundColor: themeColors.buttonColor//themeColors.chatBoxColorisDarkMode ? themeColors.chatBoxColor : ''
      }}>
        <Box sx={{ margin: 'auto', color: '#FFFFFF' }}>
          <Box className="bubble_container">
            <Box sx={{position: 'relative'}}>
            <Box sx={{background: buttonColor}}onClick={() => handleSharing(setModalVisible, setShowChatShare, false)} className={buttonChatClass}>
              Share Incite
            </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { handleSharing, ShareModal, ShareButtonChat }

