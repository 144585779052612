import React from "react";
import { Box } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import {
  ResponseWaiting,
  ResponseWaitingSecond,
  OnDemandWaiting,
} from "./ResponseWaiting";
import "../../../stylesheets/message.css";
import { isDarkMode } from "../../utils/constants";
import { isMobileBrowser } from "../../utils/user_agent";
import {
  charting,
  ScaleSelector,
  ChartStyleSelector,
  ScaleSelectorDropdown,
} from "./charting";
import { RenderResponseString } from "./utils";
import { connectPriceSocket } from "../../api/chat_websocket";
import { getPricingData } from "../../api/initiate_websocket_data";
import { LIVE_CHART_WEBSOCKET } from "../../utils/constants";
import DisplayData from "./DisplayData";

const ChatMessage = (props) => {
  const {
    idx, messageThread, loadingResponse, isLastMessage, showLoadingWaitingMessage, setShowLoadingWaitingMessage,
    setModalVisible, setShowChatShare, questionOnDemand,
    preventQuestionInput, setPreventQuestionInput, onQuestionSubmit, secondaryExperienceType,
    isLoggedIn, appProps, freeUnlimitedOnly, hasShownFreeUnlimitedMessage,
  } = props

  const {
    watchlistData,
    setShowWatchlistBlueDot,
    setShowWatchlistFlicker,
    userData,
    setUserData,
  } = appProps;
  const [showAddWatchlist, setShowAddWatchlist] = useState(false);
  const [countOfWatchlistTicker, setCountOfWatchlistTicker] = useState(null);
  const showLoadingWaitingMessageRef = useRef(showLoadingWaitingMessage);
  showLoadingWaitingMessageRef.current = showLoadingWaitingMessage;
  const questionOnDemandRef = useRef(questionOnDemand);
  questionOnDemandRef.current = questionOnDemand;
  const [hasRendered, setHasRendered] = useState(false);
  const isMobile = isMobileBrowser();
  const [selectedScale, setSelectedScale] = useState("1day"); // This is the default
  const [chartViewType, setChartViewType] = useState("line");
  const subscriptionRef = useRef(null);
  const [animatedItems, setAnimatedItems] = useState({});
  const [latestTickerPrices, setLatestTickerPrices] = useState(null);
  const [connectedSocket, setConnectedSocket] = useState(false);
  const [displayUpdatedInfo, setDisplayUpdatedInfo] = useState(null);
  const [availableChartScales, setAvailableChartScales] = useState([
    { label: "1 day", value: "1day" },
  ]);
  const [scaleChanged, setScaleChanged] = useState("1day");

  useEffect(() => {
    if (messageThread.tickerPrices && messageThread.tickerPrices.length) {
      let newScales = [];
      if (
        messageThread.ticker1minPrices &&
        messageThread.ticker1minPrices.length > 10
      ) {
        // require a few values for intraday...
        newScales.push({ label: "1 min", value: "1min" });
      }
      if (
        messageThread.ticker5minPrices &&
        messageThread.ticker5minPrices.length > 10
      ) {
        // require a few values for intraday...
        newScales.push({ label: "5 min", value: "5min" });
      }
      if (selectedScale == "1day") {
        setLatestTickerPrices(messageThread.tickerPrices);
        setScaleChanged("1day");
      } else if (selectedScale == "5min") {
        setLatestTickerPrices(messageThread.ticker5minPrices);
        setScaleChanged("5min");
      } else {
        setLatestTickerPrices(messageThread.ticker1minPrices);
        setScaleChanged("1min");
      }
      const scaleArr = [...newScales, ...availableChartScales];
      const uniqueScales = scaleArr.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.value === obj.value)
      );
      setAvailableChartScales(uniqueScales);
    }
  }, [isLastMessage, messageThread.tickerPrices, selectedScale]);

  useEffect(() => {
    if (messageThread.tickerDescription) {
      setDisplayUpdatedInfo({
        currentPriceUpdatedAt: messageThread.currentPriceUpdatedAt,
        pricePctChange1d: messageThread.pricePctChange1d,
        pricePctChange24hr: messageThread.pricePctChange24hr,
        pricePctChangeAh: messageThread.pricePctChangeAh,
        currentPrice: messageThread.currentPrice,
        closePrice: messageThread.closePrice,
      });
    }
  }, [messageThread && messageThread.tickerDescription]);

  useEffect(() => {
    if (
      isLastMessage &&
      messageThread.answeredTickerIds &&
      messageThread.answeredTickerIds.length > 0
    ) {
      const ids = [];
      Object.keys(watchlistData).forEach((k) =>
        watchlistData[k].forEach((v) => ids.push(v.id))
      );
      let inList = false;
      messageThread.answeredTickerIds.forEach((v) => {
        if (ids.includes(v)) inList = true;
      });
      if (!inList) setShowAddWatchlist(true);
      setCountOfWatchlistTicker(ids.length);
    }
  }, [isLastMessage, messageThread.answeredTickerIds, watchlistData]);

  useEffect(() => {
    if (!loadingResponse && showLoadingWaitingMessageRef.current !== null) {
      setShowLoadingWaitingMessage(0);
    }
    if (questionOnDemand) {
      setTimeout(() => {
        if (
          loadingResponse &&
          isLastMessage &&
          showLoadingWaitingMessageRef.current == 0
        ) {
          setShowLoadingWaitingMessage("demand-1");
        }
      }, 2000);
    } else {
      setTimeout(() => {
        if (
          loadingResponse &&
          isLastMessage &&
          showLoadingWaitingMessageRef.current !== null &&
          questionOnDemandRef.current !== true
        ) {
          setShowLoadingWaitingMessage(1);
        }
      }, 10000);
      setTimeout(() => {
        if (
          loadingResponse &&
          isLastMessage &&
          showLoadingWaitingMessageRef.current !== null &&
          questionOnDemandRef.current !== true
        ) {
          setShowLoadingWaitingMessage(2);
        }
      }, 16000);
      setTimeout(() => {
        if (
          loadingResponse &&
          isLastMessage &&
          showLoadingWaitingMessageRef.current !== null &&
          questionOnDemandRef.current !== true
        ) {
          setShowLoadingWaitingMessage(3);
        }
      }, 22000);
      setTimeout(() => {
        if (
          loadingResponse &&
          isLastMessage &&
          showLoadingWaitingMessageRef.current !== null &&
          questionOnDemandRef.current !== true
        ) {
          setShowLoadingWaitingMessage(4);
        }
      }, 28000);
    }
  }, [
    loadingResponse && isLastMessage,
    showLoadingWaitingMessage,
    questionOnDemand,
  ]);

  useEffect(() => {
    if (
      !!latestTickerPrices &&
      isLastMessage &&
      userData &&
      userData.current_session_id &&
      messageThread.answeredTickerIds &&
      messageThread.answeredTickerIds.length
    ) {
      // don't connect websocket until user data is set and current_session_id (used for channel)
      // NEED OT ENSURE answeredTickerIds matches the chart shown? Is there only 1 is it last in the index?
      console.log(
        "LIVE-CHART WS ONLY CALLED ONCE.",
        messageThread.answeredTickerIds
      );
      setTimeout(() => {
        getPricingData(
          LIVE_CHART_WEBSOCKET,
          messageThread.answeredTickerIds[0]
        );
      }, 500);
      if (!connectedSocket) {
        setConnectedSocket(true);
        connectPriceSocket(
          userData.current_session_id,
          subscriptionRef,
          setAnimatedItems,
          latestTickerPrices,
          setLatestTickerPrices,
          setDisplayUpdatedInfo
        );
      }
      return () => {
        if (subscriptionRef.current) {
          console.log("LIVE-CHART: Called unsubscribe... ????");
          subscriptionRef.current.unsubscribe(); // Unsubscribe on component unmount
        }
      };
    }
  }, [
    userData && userData.current_session_id,
    !!latestTickerPrices,
    isLastMessage,
  ]);

  const chartContainerRef = charting({
    idx,
    messageThread,
    hasRendered,
    isLastMessage,
    setHasRendered,
    selectedScale,
    chartViewType,
    latestTickerPrices,
    availableChartScales,
    scaleChanged,
  });

  const { isButton } = messageThread;

  let leftChatClass = isDarkMode
    ? "left-chat-box dark chat-message"
    : "left-chat-box chat-message";
  let leftOverlapCoverClass = isDarkMode
    ? "left-box-overlap-cover dark"
    : "left-box-overlap-cover";
  let rightChatClass = isDarkMode
    ? "right-chat-box dark chat-message"
    : "right-chat-box chat-message";
  let rightOverlapCoverClass = isDarkMode
    ? "right-box-overlap-cover dark"
    : "right-box-overlap-cover";

  // console.log("userData?", userData)
  // console.log("chartViewType???", chartViewType)
  return (
    <Box className="chat_message_container" sx={{ width: "90%" }}>
      {messageThread.sender == "human" && (
        <Box className="outgoing_container">
          {!isButton && (
            <Box sx={{ margin: "auto", color: isDarkMode ? "#F5F5F5" : "#121212" }}>
              <Box className="bubble_container">
                <div className={rightChatClass} style={{ fontWeight: 500 }}>
                  {messageThread.message}
                  <Box className={rightOverlapCoverClass} />
                </div>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {messageThread.sender == "bot" && (
        <Box
          className="bubble_container"
          sx={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Box className={leftChatClass}>
            {RenderResponseString({ messageThread })}

            {/* { (hasRendered && !freeUnlimitedOnly) && */}
            { (hasRendered) &&
              <Box sx={{ marginTop: isMobile ? '0px' : '8px', marginLeft: isMobile ? '0px' :'20px', marginRight: isMobile ? '0px' :'20px', marginBottom: isMobile ? '0px' :'20px', marginTop: isMobile ? '0px' : '6px' }}>
                {/* Kinda jank, but for mobile, we define these bottons outside of the chart box; otherwise they are within */}
                { (isMobile && messageThread.tickerPrices && messageThread.tickerPrices.length > 0) && 
                    <Box sx={{ 
                      position: 'relative', 
                      display: 'flex', justifyContent: 'center', alignItems: 'center',
                      height: '60px',
                      width: '100%',
                    }}>
                      <ChartStyleSelector chartViewType={chartViewType} setChartViewType={setChartViewType} />
                      <ScaleSelectorDropdown selectedScale={selectedScale} setSelectedScale={setSelectedScale} availableChartScales={availableChartScales}/>
                    </Box>
                }
                <Box id={`chart-container-${idx}`} sx={{ minHeight: '300px', width: '100%', marginTop: isMobile ? '-10px' : '10px', position: 'relative' }}>
                  { (!isMobile && messageThread.tickerPrices && messageThread.tickerPrices.length > 0) && 
                    <>
                      <ChartStyleSelector chartViewType={chartViewType} setChartViewType={setChartViewType} />
                      <ScaleSelector selectedScale={selectedScale} setSelectedScale={setSelectedScale} availableChartScales={availableChartScales}/>
                    </>
                  }
                  <canvas ref={chartContainerRef} id={`myChart-${idx}`} />
                </Box>
              </Box>
            }
            {displayUpdatedInfo && (
              <DisplayData
                appProps={appProps}
                messageThread={messageThread}
                isLastMessage={isLastMessage}
                loadingResponse={loadingResponse}
                showAddWatchlist={showAddWatchlist}
                displayUpdatedInfo={displayUpdatedInfo}
                preventQuestionInput={preventQuestionInput}
                setPreventQuestionInput={setPreventQuestionInput}
                secondaryExperienceType={secondaryExperienceType}
                onQuestionSubmit={onQuestionSubmit}
                setModalVisible={setModalVisible}
                setShowChatShare={setShowChatShare}
                isLoggedIn={isLoggedIn}
                countOfWatchlistTicker={countOfWatchlistTicker}
                setShowAddWatchlist={setShowAddWatchlist}
                freeUnlimitedOnly={freeUnlimitedOnly}
                hasShownFreeUnlimitedMessage={hasShownFreeUnlimitedMessage}
              />
            )}
            <Box className={leftOverlapCoverClass} />
          </Box>
        </Box>
      )}
      {loadingResponse && isLastMessage && (
        <Box sx={{ 
          flexDirection: "column",
          justifyContent: isMobile ? "flex-start" : 'flex-end',
          width: '100%'
          }}>
              {showLoadingWaitingMessage == "demand-1" && (
            <OnDemandWaiting
              text={"Updating Real-Time"}
              sx={{ width: "152" }}
              hideDotDotDot={true}
            />
          )}
          {showLoadingWaitingMessage == 1 && (
            <ResponseWaitingSecond
              text={"Thinking"}
              sx={{ width: "115px" }}
            />
          )}
          {showLoadingWaitingMessage == 2 && (
            <ResponseWaitingSecond
              text={"Finding relevant data"}
              sx={{ width: "175px" }}
            />
          )}
          {showLoadingWaitingMessage == 3 && (
            <ResponseWaitingSecond
              text={"Updating data"}
              sx={{ width: "150px" }}
            />
          )}
          {showLoadingWaitingMessage == 4 && (
            <ResponseWaitingSecond
              text={"Responding"}
              sx={{ width: "112px" }}
            />
          )}
          {showLoadingWaitingMessage == 0 && <ResponseWaiting />}
        </Box>
      )}

      {loadingResponse && isLastMessage && (
        <>
        
        </>
      )}
    </Box>
  );
};

export default ChatMessage;
