
const getAllDirectives = async (setDirectiveData) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        admin {
          getAllDirectives {
            sanitizationDirectives {
              id
              status
              directiveText
              createdAt
            }
            baseDirectives {
              id
              status
              directiveText
              createdAt
            }
            shortenedBaseDirectives {
              id
              status
              directiveText
              createdAt
            }
            watchlistDirectives {
              id
              status
              directiveText
              createdAt
            }
          }
        }
      }`
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    console.log("DATA?", data)
    setDirectiveData(data.data.admin.getAllDirectives)
    // const newFeedbackItem = data.data.admin.getUserFeedbacks
    // if (newFeedbackItem && newFeedbackItem.length > 0) {
    //   setUserSelectedList((prevList) => ({
    //     ...prevList, 
    //     [newFeedbackItem[0].userId]: newFeedbackItem // They'll all have same user id, so just grab first
    //   }));
    // }
  })
  .catch(error => console.error('Error fetching data:', error));
};


const validateDirectiveText = async (directiveType, directiveText) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        {
          admin {
            validateDirective(directiveType: "${directiveType}", directiveText: """${directiveText}""") {
              status
              error
            }
          }
        }
      `
    })
  };

  try {
    const response = await fetch('/graphql', requestOptions);
    const result = await response.json();
    console.log("result?", result)
    const { status, error } = result?.data?.admin?.validateDirective || {};
    console.log("status, error", status, error)
    if (status !== "200") {
      throw new Error(error || 'Validation failed');
    }

    return { success: true };
  } catch (err) {
    console.error('Validation error:', err);
    return { success: false, error: err.message };
  }
};



const updateDirective = async (directiveType, directiveText, isTesting) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        {
          admin {
            updateDirective(directiveType: "${directiveType}", directiveText: """${directiveText}""", isTesting: ${isTesting}) {
              status
              error
            }
          }
        }
      `
    })
  };

  try {
    const response = await fetch('/graphql', requestOptions);
    const result = await response.json();
    console.log("result??", result)
    const { status, error } = result?.data?.admin?.updateDirective || {};
    console.log("status, error", status, error)
    if (status !== "200") {
      throw new Error(error || 'Update failed');
    }

    return { success: true };
  } catch (err) {
    console.error('Update error:', err);
    return { success: false, error: err.message };
  }
};

const deleteDirective = async (directiveId) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        {
          admin {
            deleteDirective(directiveId: ${directiveId}) {
              status
              error
            }
          }
        }
      `
    })
  };

  try {
    const response = await fetch('/graphql', requestOptions);
    const result = await response.json();
    console.log("result??", result)
    const { status, error } = result?.data?.admin?.deleteDirective || {};
    console.log("status, error", status, error)
    if (status !== "200") {
      throw new Error(error || 'Delete failed');
    }

    return { success: true };
  } catch (err) {
    console.error('Delete error:', err);
    return { success: false, error: err.message };
  }
};


export { getAllDirectives, validateDirectiveText, updateDirective, deleteDirective }
