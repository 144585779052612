import { Box } from '@mui/material';
// import BackArrow from '../../../javascript/images/BackArrow.png'
import BackArrowBlue from '../../../javascript/images/BackArrow.png'
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isDarkMode, themeColors } from "../../utils/constants";

const Back = (props) => {
  const navigate = useNavigate()
  const {
    setMethod, value
  } = props
  return (
  <Box className="about-back-container">
    <IconButton
      onClick={() => {
        if (setMethod) {
          setMethod(value)
        } else {
          navigate(-1)
        }
      }}
      size="small" aria-haspopup="true"
    ><ArrowBackIcon sx={{ color: themeColors.primaryText }} />
    </IconButton>
  </Box>
  )
}
export default Back
