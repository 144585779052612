import { Box, Button, FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel } from '@mui/material';
import '../../../stylesheets/plan-selection.css'
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isDarkMode, themeColors } from '../../utils/constants';
import { Navbar } from '../../components/Navbar';
import { confirmEmailForUser, getUserFeedbacks, getAllUserFeedbacks, sendUserFeedbackEmailReply, setUserFeedbackResolved, sendUserUnverifiedEmailReply } from './admin_user_actions';
import { timeAgo, Text, formatDateTime } from './utils';
import { POSSIBLE_ERROR_REPORT_RESPONSES, POSSIBLE_EMAIL_VERIFICATION_RESPONSES } from './constants';
import CircularProgress from '@mui/material/CircularProgress';

const QueryParsing = () => {
  
  return (
    <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', gap: '15px'}}>

      {Text("1. Input field of query")}
      {Text("2. Show output of refiend query")}
      {Text("3. Run Parsing Algo")}
      {Text("4. Output of tickers and sectors")}
      {Text("5. Link to Tickers tab to pull tickers and assess data")}
    </Box>
  )

}

export { QueryParsing } 
