import { createTheme } from "@mui/material/styles";
// import { useEffect, useState } from "react"
// Prompt was: prompt = "Generate 50 responses with the following criteria: \"Generate a mellow greeting for a user who wants to ask about stocks. The greeting should be 10 words or less and guide the user to ask questions about stocks.\""
export const possibleStartingMessages = [
  "You can ask me pretty much anything about stocks..",
  "I have stock market insights. Ask away.",
  "Here to help you with stocks. ",
  "Here to help you with any questions about stocks.",
  "It's a good day to talk about stocks.",
  "I'm ready to assist with your stocks.",
  "Welcome! I'm here to guide you with stocks.",
  "Hey there! I'm here to help you invest in stocks.",
  "Good day! Let's talk about the stock market.",
  "Hi! I'm here to help with your stock portfolio.",
  "Good day! Let me assist you with your stocks.",
  "Welcome! I'm here to support you with stocks.",
  "Hello! Let's talk about your stock investments.",
  "Hi! I'm your source for stock insights.",
  "Hello there! I have stock market insights. Ask away!",
  "Hi there! Let's explore the world of stocks.",
  "Welcome! Let's chat about your stocks.",
  "Hi there! I can help you with you with the stock market.",
  "Good day! I'm your stock market guide.",
  "Hi! I'm your go-to for stock market analysis.",
  "Good day! Let's discuss the latest stock data.",
  "Hello there! I'm here to help you invest in the stock market.",
  "Welcome! I'm your source for the latest stock insights.",
  "Good day! I can assist with your stock market needs.",
  "Welcome! Let me assist you with your stock market decisions.",
];

export const requireCreateAccountMessage =
  "Continue Using Incite – Create Your Free Account Now.";
export const requireLoginMessage =
  "Welcome back! Sign in to continue using Incite";
export const expiredSessionMessage =
  "Your session has expired, please log in again";

export const defaultServerErrorMessage =
  "Whoops! Something went wrong. Please try again, but if the issue persists please contact us at help@inciteai.com";

//export const firstUserMessage = 'Our AI I will give you answers in real-time.\n\nClick below\n\nOr ask me anything about stocks.'
export const firstUserMessage =
  "Ask me about stocks and crypto.\nGet real-time insights now.\nClick below.";
export const askShareMessage =
  "Got friends with stocks and crypto?\nInvite them to join Incite!\nTap below";

export const defaultPaywallModalMessage = "You've reached your daily limit.";
export const watchlistPaywallModalMessage =
  "Subscribe to customize your watchlist.";

const MAX_WATCHLIST_ITEMS_SUBSCRIBER = 30;
const MAX_WATCHLIST_ITEMS = 10;
export const get_max_watchlist_items = (user) => {
  if (user && user.has_active_subscription) {
    return MAX_WATCHLIST_ITEMS_SUBSCRIBER;
  }
  return MAX_WATCHLIST_ITEMS;
};

export const returningChatStartingResponse = {
  sender: "bot",
  message: "",
};

export const chatStartingResponse = {
  sender: "bot",
  //message: 'You can ask me pretty much anything about stocks..'
  message:
    possibleStartingMessages[
      Math.floor(Math.random() * possibleStartingMessages.length)
    ],
};

export const chatSartingQuestions = [
  {
    sender: "human",
    message: window.globals.ambiguous_questions.question_simple[0],
    isButton: true,
    tickers: window.globals.ambiguous_questions.question_simple[1],
  },
  {
    sender: "human",
    message: window.globals.ambiguous_questions.question_advanced[0],
    isButton: true,
    tickers: window.globals.ambiguous_questions.question_advanced[1],
  },
];

export const questionDetailedText = window.globals.question_detailed_text;
export const questionRegularText = window.globals.question_regular_text;

export const buttonsMoreButtonsQuestions =
  window.globals.buttons_more_buttons_questions;

//export const startPlaceholderText = 'Ask me about stocks or crypto..'//'Is it a good time to buy tesla stock?'
export const startPlaceholderText = "Message";
export const firstUserPlaceholderText = "Ask me about stocks or crypto..";

export const isProduction = (() => {
  const NODE_ENV = process.env.NODE_ENV;
  return !!NODE_ENV && NODE_ENV != "development";
})();

export const WATCHLIST_WEBSOCKET = "WATCHLIST";
export const LIVE_CHART_WEBSOCKET = "CHART";

export const websocketUrl = (() => {
  console.log("in websocketUrl.. ")
  const NODE_ENV = process.env.NODE_ENV;
  console.log("NODE_ENV:", NODE_ENV)
  if (NODE_ENV == "development") {
    //return "ws://localhost:3000/subscriptions"
    return "ws://localhost:3001/subscriptions";
  } else if (NODE_ENV == "staging") {
    return "wss://staging.inciteai.com/subscriptions";
  } else {
    return "wss://app.inciteai.com/subscriptions";
  }
})();

export const appleRedirectUrl = (() => {
  const NODE_ENV = process.env.NODE_ENV;
  if (NODE_ENV == "development") {
    return "https://27f6-76-81-46-197.ngrok-free.app/register";
  } else if (NODE_ENV == "staging") {
    return "https://staging.inciteai.com/register";
  } else {
    return "https://app.inciteai.com/register";
  }
})();

export const isDarkMode = (() => {
  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  // Could do a use state & use effect to look for changes; but fine to just set on refresh
  // const listener = (event) => setIsDark(event.matches);
  // mediaQuery.addEventListener('change', listener);
  return mediaQuery.matches;
})();

export const themeColors = (() => {
  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  if (mediaQuery.matches) {
    return {
      navColor: "#121212", //'#262626',//'#393939',//"rgba(69,69,69)",
      backgroundColor: "#121212", //#262626',//'#393939',
      color: "#f5f5f5",
      modalColor: "#f5f5f5",
      linkColor: "#2e7ff1", //'#5390E6',//'#5390E6',
      buttonPrimaryColor: "#252525",
      primaryText: '#F5f5f5',
      SignUpButtonColor: "#ECECEC", //'#000',//'#2971D7',//'#2D2D2D',//'#424242',

      showHistoryContainerColor: "#2D2D2D",
      showHistoryColor: "#2971D7", //'#5390E6',

      chatBoxColor: "#121212", //'#262626',//'#393939',
      
      inciteTextColor: "#fff",

      registerMessageColor: "#FFFFFF",
      registerMessageContainerColor: "#2971D7", //'#5390E6',

      copyContainer: "rgba(0, 0, 0, 0.83)",
      copyText: "#fff",
    };
  } else {
    return {
      navColor: "#F5F5F5", //'#FEFEFE', // Already specified in the css styles
      backgroundColor: "#F5F5F5", //'#FDFDFD',
      color: "#121212",
      modalColor: "#121212", //'#fff',
      linkColor: "#2e7ff1", //'#5390E6',//'#1442E8',
      buttonPrimaryColor: "#E8E8E8", //'#5390E6',
      SignUpButtonColor: "#ECECEC", //'#000',//'#2971D7',//'#FEFEFE',
      primaryText: '#121212',

      showHistoryContainerColor: "#2971D7",
      showHistoryColor: "white",
      chatBoxColor: "#F5F5F5",

      inciteTextColor: "#555454",

      registerMessageColor: "#FFFFFF",
      registerMessageContainerColor: "#2971D7", //'#5390E6',

      copyContainer: "rgba(0, 0, 0, 0.83)",
      copyText: "#fff",
    };
  }
})();

export const pofessionsList = [
  "Tech",
  "Finance",
  "Media",
  "Health",
  "Law",
  "Other",
];

export const customSelectTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: themeColors.modalColor,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: themeColors.modalColor,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: themeColors.modalColor,
          },
          "& .MuiInputBase-input": {
            color: themeColors.modalColor,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: themeColors.modalColor,
          "&.Mui-focused": {
            color: themeColors.modalColor,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: themeColors.modalColor,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          color: "white",
          backgroundColor: "rgb(57,57,57)",
        },
        listbox: {
          backgroundColor: "rgb(57,57,57)",
        },
      },
    },
  },
});

// SAME as above theme; except is all light mode for select fields!
export const customSelectThemeModal = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "& .MuiInputBase-input": {
            color: "#fff",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: "#fff",
          "&.Mui-focused": {
            color: "#fff",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#fff",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          color: "white",
          backgroundColor: "rgb(57,57,57)",
        },
        listbox: {
          backgroundColor: "rgb(57,57,57)",
        },
      },
    },
  },
});
