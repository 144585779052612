import { Box } from "@mui/material";
import { isDarkMode } from "../../utils/constants";

const timeAgo = (timestamp) => {
 const isoString = timestamp.replace(" UTC", "Z");

  const createdDate = new Date(isoString);
  if (isNaN(createdDate)) {
    return "Invalid Date";
  }

  // 3) Calculate the difference
  const now = new Date();
  let diffInSeconds = Math.floor((now - createdDate) / 1000);

  // If timestamp is in the future, handle it:
  if (diffInSeconds < 0) {
    // Option A: clamp negative to 0 so it's "just now"
    // diffInSeconds = 0;

    // Option B: show “In the future”
    return "In the future";
  }

  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(months / 12);

  // 4) Return the human-readable string
  if (minutes < 120) {
    return `${minutes} min${minutes !== 1 ? "s" : ""} ago`;
  } else if (hours < 48) {
    return `${hours} hr${hours !== 1 ? "s" : ""} ago`;
  } else if (days < 14) {
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (weeks < 8) {
    return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
  } else if (months < 24) {
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  }
}

const formatDateTime = (timestamp) => {
  // If there's no timestamp, return "Unresolved"
  if (!timestamp) {
    return "Unresolved";
  }

  // Convert "2025-03-02 04:08:21 UTC" to a standard ISO string: "2025-03-02T04:08:21Z"
  const isoString = timestamp.replace(" UTC", "Z");
  const date = new Date(isoString);

  // If the date is invalid, treat as unresolved
  if (isNaN(date)) {
    return "Unresolved";
  }

  // Extract pieces
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Return a "Resolved at" string
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

const Text = (text) => {
  return(
    <Box className="base-text" sx={{
      fontWeight: '500', fontSize: '15px', color: '#F6F6F6',
      color: isDarkMode ? '#F6F6F6' : '#1D1D1F'
    }}>
      {text}
    </Box>
  )
}

export { timeAgo, formatDateTime, Text };
