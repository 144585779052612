import {
  Box,
  TextField,
  FormControl,
  MenuItem,
  Select,
  Divider,
  Button,
  CircularProgress,
} from '@mui/material';
import '../../../stylesheets/plan-selection.css';
import { useState, useEffect } from 'react';
import { isDarkMode } from '../../utils/constants';
import { Text } from './utils';
import { getAllDirectives, validateDirectiveText, updateDirective, deleteDirective } from './api/directives';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const textFieldSx = {
  width: '100%',
  maxWidth: '95%',
  maxHeight: '300px',
  overflowY: 'auto',
  backgroundColor: isDarkMode ? '#1D1D1F' : '#FFFFFF',
  color: isDarkMode ? '#F6F6F6' : '#1D1D1F',
  '& .MuiInputBase-root': {
    color: isDarkMode ? '#F6F6F6' : '#1D1D1F',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: isDarkMode ? '#F6F6F6' : '#ccc',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: isDarkMode ? '#A0A0A0' : '#888',
  },
};

const selectSx = {
  height: '40px',
  width: '340px',
  backgroundColor: isDarkMode ? '#131521' : '#F6F6F6',
  color: isDarkMode ? '#F6F6F6' : '#1D1D1F',
  '& .MuiSvgIcon-root': {
    color: isDarkMode ? '#F6F6F6' : '#1D1D1F',
  },
  '& fieldset': {
    borderColor: isDarkMode ? '#F6F6F6' : '#ccc',
  },
  '&:hover fieldset': {
    borderColor: isDarkMode ? '#A0A0A0' : '#888',
  },
};

const QueryDirectives = () => {
  const [directiveData, setDirectiveData] = useState(null);
  const [selectedDirectiveData, setSelectedDirectiveData] = useState({});
  const [editedTexts, setEditedTexts] = useState({});
  const [validatingKeys, setValidatingKeys] = useState({});
  const [validatedKeys, setValidatedKeys] = useState({});

  useEffect(() => {
    getAllDirectives(setDirectiveData);
  }, []);

  const handleTextChange = (key, text) => {
    setEditedTexts(prev => ({
      ...prev,
      [key]: text,
    }));
    setValidatedKeys(prev => ({
      ...prev,
      [key]: false,
    }));
  };

  const handleValidateDirectiveText = async (key) => {
    setValidatingKeys(prev => ({ ...prev, [key]: true }));
    const textToValidate = editedTexts[key];
    const result = await validateDirectiveText(key, textToValidate);
    setValidatingKeys(prev => ({ ...prev, [key]: false }));
    if (result.success) {
      setValidatedKeys(prev => ({ ...prev, [key]: true }));
    } else {
      alert(result.error);
    }
  };

  
  const handleUpdateDirective = async (key, isTesting) => {
    const textToUpdate = editedTexts[key];
    const result = await updateDirective(key, textToUpdate, isTesting);
  
    if (result.success) {
      // Re-fetch and reset validation state
      getAllDirectives((newData) => {
        setDirectiveData(newData);
        const updated = newData?.[key]?.[0];
        setSelectedDirectiveData(prev => ({ ...prev, [key]: updated }));
        setEditedTexts(prev => ({ ...prev, [key]: updated?.directiveText || '' }));
        setValidatedKeys(prev => ({ ...prev, [key]: false }));
      });
    } else {
      alert(result.error);
    }
  };

  const handleDeleteDirective = async (key, directiveId) => {
    const result = await deleteDirective(directiveId);
    if (result.success) {
      // Re-fetch and reset validation state
      getAllDirectives((newData) => {
        setDirectiveData(newData);
        const updated = newData?.[key]?.[0];
        setSelectedDirectiveData(prev => ({ ...prev, [key]: updated }));
        setEditedTexts(prev => ({ ...prev, [key]: updated?.directiveText || '' }));
        setValidatedKeys(prev => ({ ...prev, [key]: false }));
      });
    } else {
      alert(result.error);
    }
  };



  const directiveConfigs = [
    { label: 'Sanitization Directive', key: 'sanitizationDirectives' },
    { label: 'Base Directive', key: 'baseDirectives' },
    { label: 'Shortened Base Directive', key: 'shortenedBaseDirectives' },
    { label: 'Watchlist Directive', key: 'watchlistDirectives' },
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px',
        height: '100vh',
        overflowY: 'auto',
        backgroundColor: isDarkMode ? '#121212' : '#f9f9f9',
      }}
    >
      {/* {Text('Full data insert based on tickers (from ticker tab)')} */}
  
      {directiveConfigs.map(({ label, key }, idx) => {
        const originalText = selectedDirectiveData?.[key]?.directiveText || '';
        const editedText = editedTexts[key] ?? originalText;
        const isModified = editedText !== originalText;
        const isValidating = validatingKeys[key];
        const isValidated = validatedKeys[key];
        const directiveId = selectedDirectiveData?.[key]?.id
        const status = selectedDirectiveData?.[key]?.status
        console.log("STATUS?", status, directiveId, key)
  
        return (
          <Accordion
            key={key}
            disableGutters
            sx={{
              backgroundColor: isDarkMode ? '#1D1D1F' : '#fff',
              color: isDarkMode ? '#F6F6F6' : '#1D1D1F',
              boxShadow: 'none',
              border: `1px solid ${isDarkMode ? '#333' : '#ccc'}`,
              borderRadius: '8px',
              maxWidth: '90%',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: isDarkMode ? '#F6F6F6' : '#1D1D1F' }} />}
              aria-controls={`${key}-content`}
              id={`${key}-header`}
            >
              <Typography sx={{ fontWeight: 600 }}>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DirectiveSelection
                directiveData={directiveData}
                setSelectedDirectiveData={setSelectedDirectiveData}
                setEditedTexts={setEditedTexts}
                directiveKey={key}
              />
  
              <TextField
                fullWidth
                multiline
                minRows={10}
                variant="outlined"
                sx={{ ...textFieldSx, marginTop: '10px' }}
                value={editedText}
                onChange={(e) => handleTextChange(key, e.target.value)}
              />
  
              <Box
                sx={{
                  marginTop: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {isValidating ? (
                  <CircularProgress size={22} />
                ) : isValidated ? (
                  <>
                    <span style={{ color: isDarkMode ? '#00FF90' : '#228B22' }}>✅ Validated</span>
                    <Button variant="outlined" onClick={() => handleUpdateDirective(key, true)}>
                      Test
                    </Button>
                    <Button variant="outlined" onClick={() => {
                      if (window.confirm("Are you sure you want to activate and push this directive?")) {
                        handleUpdateDirective(key, false);
                      }
                    }}>
                      Activate and Push
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    // disabled={!isModified}
                    onClick={() => handleValidateDirectiveText(key)}
                  >
                    Validate
                  </Button>
                )}

                { (status == "testing") &&
                  <Button variant="outlined" onClick={() => {
                    if (window.confirm("Are you sure you want to delete this test directive? This cannot be undone.")) {
                      handleDeleteDirective(key, directiveId);
                    }
                  }}>
                    Delete
                  </Button>
                }

              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
  
      {/* Spacer at the bottom to avoid cutoff */}
      <Box sx={{ minHeight: '200px'}} />
    </Box>
  );
  
};


const DirectiveSelection = ({
  directiveData,
  setSelectedDirectiveData,
  setEditedTexts,
  directiveKey
}) => {
  const [selectedVal, setSelectedVal] = useState(null);
  const curDirectiveData = directiveData?.[directiveKey];

  useEffect(() => {
    if (curDirectiveData?.length > 0) {
      const first = curDirectiveData[0];
      setSelectedVal(first.id);
      setSelectedDirectiveData(prev => ({ ...prev, [directiveKey]: first }));
      setEditedTexts(prev => ({ ...prev, [directiveKey]: first.directiveText }));
    }
  }, [curDirectiveData]);

  const handleChange = (e) => {
    const selectedId = e.target.value;
    setSelectedVal(selectedId);
    const selectedObj = curDirectiveData.find(obj => `${obj.id}` === selectedId);
    if (selectedObj) {
      setSelectedDirectiveData(prev => ({ ...prev, [directiveKey]: selectedObj }));
      setEditedTexts(prev => ({ ...prev, [directiveKey]: selectedObj.directiveText }));
    }
  };

  return (
    <Box sx={{ height: '40px', marginTop: '10px' }}>
      <FormControl sx={{ width: 260 }}>
        <Select
          value={selectedVal}
          onChange={handleChange}
          displayEmpty
          sx={selectSx}
        >
          {curDirectiveData?.map((obj) => (
            <MenuItem key={obj.id} value={`${obj.id}`}>
              {`${new Date(obj.createdAt.replace(' UTC', 'Z')).toLocaleString('en-US', {
                timeZone: 'America/Los_Angeles',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
              })} - ${obj.status}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};


export { QueryDirectives };
