
const POSSIBLE_ERROR_REPORT_RESPONSES = [
`
Hi {FIRST_NAME},

Thank you for reaching out, a member of our team will assist you shortly. 

Warm regards,
Nic`,

`
Hi {FIRST_NAME},

Thank you for reporting, a member of our team will review shortly. 

Best,
Nic`,

`
Hi {FIRST_NAME},

A member of our team will assist you shortly. 

Thank you,
Nic`,
]

const POSSIBLE_EMAIL_VERIFICATION_RESPONSES = [
`
Hi {FIRST_NAME},

Thank you for reaching out, I have gone ahead and confirmed your account. You will be able to continue using the <a href="https://app.inciteai.com/chat" target="_blank">Incite Web App</a>. 

Best,
Nic
`
]


export { POSSIBLE_ERROR_REPORT_RESPONSES, POSSIBLE_EMAIL_VERIFICATION_RESPONSES }
