import React from "react";
import { useState, useEffect, useRef } from "react"
import { isDarkMode } from "../../utils/constants"
import { Chart, registerables } from "chart.js"
import { isMobileBrowser } from "../../utils/user_agent";
import { formatLargeNumbers } from './utils'
import zoomPlugin from 'chartjs-plugin-zoom';
import { Box, Button, MenuItem, Select } from "@mui/material"
// import { FinancialChartPlugin } from 'chartjs-chart-financial';
import {
  CandlestickController, CandlestickElement, OhlcController, OhlcElement,
} from 'chartjs-chart-financial';
import 'chartjs-adapter-date-fns';

Chart.register(...registerables, zoomPlugin, 
  CandlestickController, CandlestickElement, OhlcController, OhlcElement,
  );

const charting = (props) => {
  const {
    idx, messageThread, hasRendered, isLastMessage, setHasRendered, selectedScale, chartViewType, latestTickerPrices, availableChartScales, scaleChanged
  } = props

  const isMobile = isMobileBrowser()
  const [datasets, setDatasets] = useState([])
  const chartContainerRef = useRef(null);
  const [hasSetChartInstance, setHasSetChartInstance] = useState(false)
  const [renderedChartData, setRenderedChartData] = useState(false)
  const newChartRef = useRef(null);

  const label1 = messageThread.label1
  const label3 = messageThread.label2
  const values1 = messageThread.values1
  const values3 = messageThread.values2

  let barColor = 'rgba(10,10,255, 0.25)';
  let epsColor = 'rgba(10,10,255, 0.75)';//'rgba(252,72,114, 0.7)';
  let revColor = 'rgba(45,143,230, 0.7)';
  let priceColor = 'rgba(95,95,95, 0.45)';
  if (isDarkMode) {
    barColor = 'rgba(100,150,255, 0.45)';
    epsColor = 'rgba(100,150,255, 0.95)'//'rgba(252,72,114, 0.7)';
    revColor = 'rgba(45,143,230, 0.7)';
    priceColor = 'rgba(195,195,195, 0.9)';
  }

  let showBarChart = false
  const barChartKeywords = ["Insider Transactions", "Volume"]
  if (barChartKeywords.includes(label1)) {
    showBarChart = true
  }

  const tickerPrices = latestTickerPrices

  // useEffect(() => {
  //   if (messageThread.tickerDescription && messageThread.values2 && tickerPrices) {
  //     let dataSetContent = [
  //       {
  //         label: label3,
  //         data: values3,
  //         cubicInterpolationMode: 'monotone',
  //         tension: 0.4,
  //         borderColor: priceColor,
  //         textColor: priceColor,
  //         borderWidth: 1,
  //         yAxisID: 'stockPriceAxis',
  //         pointRadius: 0, // For the stock price, no need
  //       }
  //     ]

  //     if (values1 && values1.length > 0) {
  //       const adjValues = values1.map((val, index) => {
  //         let found_idx;
  //         tickerPrices.some((entry, idx) => {
  //           if (new Date(entry.date) >= new Date(val.x)) {
  //             found_idx = idx
  //             return true;
  //           }
  //           return false;
  //         })
  //         return {x: found_idx, y: val.y}
  //       }).reverse()
  //       console.log("adjValues?", adjValues)
  //       dataSetContent = [{
  //         label: label1,//'Earnings Per Share',
  //         data: adjValues,//dates.map((date, index) => ({ x: date, y: values1[index] })),//epss,
  //         type: showBarChart ? 'bar': null,
  //         cubicInterpolationMode: 'monotone',
  //         tension: 0.4,
  //         borderColor: showBarChart ? barColor: epsColor,//epsColor,
  //         textColor: epsColor,
  //         color: epsColor,
  //         labelColor: epsColor,
  //         borderWidth: showBarChart ? 1 : 2,
  //         barThickness: 2,
  //         hidden: true,
  //         yAxisID: 'epsAxis',
  //         backgroundColor: showBarChart ? barColor: epsColor,
  //         pointRadius: 0,//2, // Size of the point
  //         pointBackgroundColor: showBarChart ? barColor: epsColor,//epsColor, // Background color of the point
  //         pointBorderColor: showBarChart ? barColor: epsColor,//epsColor, // Border color of the point
  //         pointStyle: 'circle' // Shape of the point
  //       }, dataSetContent[0]]
  //     }
  //     setDatasets(dataSetContent)
  //   }
  // }, [values1, messageThread, (tickerPrices && tickerPrices.length > 0)])

  

  useEffect(() => {
    if (chartViewType == "bar" && messageThread.tickerDescription && tickerPrices) {
      setHasRendered(true)
      let dates;
      if (selectedScale == "1day") {
        dates = tickerPrices.map(entry => entry.date.replace(" UTC", ""))
      } else {
        dates = tickerPrices.map(entry => entry.datetime.replace(" UTC", ""))
      }
      const financialData = tickerPrices.map((entry, idx) => ({ x: idx, o: entry.open, h: entry.high, l: entry.low, c: entry.close }));
      const datasets = [
        {
          label: messageThread.primaryLabel,
          data: financialData,
          type: "candlestick",
          barThickness: "flex",
          backgroundColor: (context) => {
            return context.dataIndex % 2 === 0 ? "#dfe4ea" : "#ced6e0"; // Alternate bar colors for better visualization
          },
          maxBarThickness: 600,//25,        // Don’t let bars get too thick
          minBarLength: 2,            // The smallest visible bar length
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          borderColor: priceColor,
          textColor: priceColor,
          borderWidth: 1,
          pointRadius: 0, // For the stock price, no need
        },
      ];
      if (chartContainerRef.current) {
        const ctx = chartContainerRef.current.getContext("2d");
        if (newChartRef.current) {
          newChartRef.current.destroy();
        }
        newChartRef.current = new Chart(ctx, {
          type: "candlestick",
          data: { datasets },
          options: barOptions(messageThread, isMobile, dates, priceColor, values1, epsColor, showBarChart, financialData, selectedScale),
        });
      }
    }
  }, [isLastMessage, hasRendered, tickerPrices, datasets, chartViewType]);

  useEffect(() => {
    let dataValues = [];
    let dates = [];
    let primaryLabel;
    if (chartViewType == "line" && (tickerPrices && tickerPrices[0])) {
      if (tickerPrices) {
        if (scaleChanged == "1day") {  // corresponds to "1day"
          dates = tickerPrices.map((entry, idx) => entry.date.replace(" UTC", ""))
          dataValues = tickerPrices.map((entry, idx) => ({ x: entry.date.replace(" UTC", ""), y: entry.close }))
        } else {
          dates = tickerPrices.map((entry, idx) => entry.datetime.replace(" UTC", ""))
          dataValues = tickerPrices.map((entry, idx) => ({ x: entry.datetime.replace(" UTC", ""), y: entry.close }))
        }
        primaryLabel = messageThread.primaryLabel
      }
    }

    if (chartViewType == "line" && messageThread.tickerDescription && dataValues.length) {
      let showBarChart = false

      const barChartKeywords = [ "Insider Transactions", "Volume" ]
      if (barChartKeywords.includes(label1)) {
        showBarChart = true
      }
      setHasRendered(true)
      if (chartContainerRef.current) {
        const element = document.getElementById(`myChart-${idx}`)
        if (!element) { return }
        setRenderedChartData(true)
        if (newChartRef.current) {
          newChartRef.current.destroy();
        }
        let constNewDatasets;
        let datasetsArr = [
          {
            label: primaryLabel,
            data: dataValues,
            cubicInterpolationMode: 'monotone',
            tension: 0.4,
            borderColor: priceColor,
            textColor: priceColor,
            borderWidth: 1,
            yAxisID: 'stockPriceAxis',
            pointRadius: 0, // For the stock price, no need
          }
        ]
        if (values1 && values1.length > 0) {
          const adjValues = values1.map((val, index) => {
            let found_date;
            tickerPrices.some((entry, idx) => {
              if (new Date(entry.date) >= new Date(val.x)) {
                found_date = entry.date
                return true;
              }
              return false;
            })
            return {x: found_date, y: val.y}
          }).reverse()

          constNewDatasets = [
            {
              label: label1,//'Earnings Per Share',
              data: adjValues,
              type: showBarChart ? 'bar': null,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
              borderColor: showBarChart ? barColor: epsColor,//epsColor,
              textColor: epsColor,
              color: epsColor,
              labelColor: epsColor,
              borderWidth: showBarChart ? 1 : 2,
              barThickness: 2,
              yAxisID: 'epsAxis',
              backgroundColor: showBarChart ? barColor: epsColor,
              pointRadius: 0,//2, // Size of the point
              pointBackgroundColor: showBarChart ? barColor: epsColor,//epsColor, // Background color of the point
              pointBorderColor: showBarChart ? barColor: epsColor,//epsColor, // Border color of the point
              pointStyle: 'circle' // Shape of the point
            }, 
            datasetsArr[0]
          ]
        } else {
          constNewDatasets = datasetsArr
        }

        const ctx = chartContainerRef.current.getContext("2d");
        if (!ctx) return;
        if (newChartRef.current) {
          newChartRef.current.destroy();
        }
        newChartRef.current = new Chart(ctx, {
          type: "line",
          data: { labels: dates, datasets: constNewDatasets },
          // data: { datasets: constNewDatasets },
          options: lineOptions(messageThread, isMobile, dates, priceColor, values1, epsColor, showBarChart, selectedScale),
        });
        // if (newChartRef.current && newChartRef.current.data.datasets.length >= 2) {
        //   if (label1 == "Volume") { // Only hide Volume by default
        //     newChartRef.current.options.scales.epsAxis.display = false;
        //     newChartRef.current.data.datasets[0].hidden = true;
        //     newChartRef.current.update();
        //   }
        // }
      }
    }
  }, [isLastMessage, renderedChartData, hasRendered, (tickerPrices && tickerPrices[0]), (tickerPrices && tickerPrices.length > 0), chartViewType, scaleChanged]);
  

  useEffect(() => {
    if (chartViewType != "line") {
      setRenderedChartData(false)
    }
  }, [chartViewType])

  // NEED THIS FOR LINE CHART OT UPDATE
  useEffect(() => {
    if (!newChartRef.current || !tickerPrices) return;
    if (chartViewType != "line") return;
    if (!tickerPrices.length) return 
    // // This needs to be fixed based on scale (and to build out bar)
    let dataValues
    if (selectedScale == "1day") {
      dataValues = tickerPrices.map((entry, idx) => ({ x: idx, y: entry.close }))
    } else {
      dataValues = tickerPrices.map((entry, idx) => ({ x: idx, y: entry.close }))
    }

    if (newChartRef.current.data.datasets.length == 1) {
      newChartRef.current.data.datasets[0].data = dataValues;
    } else {
      // The order has the secondary at first index if present, so update live data at index 1
      newChartRef.current.data.datasets[1].data = dataValues; 
    }
    // If you have labels for the x-axis, update them:
    // chartInstance.data.labels = arrayOfDates;
    newChartRef.current.update();
  }, [chartViewType, newChartRef.current, tickerPrices, selectedScale]);

  return chartContainerRef
}


const lineOptions = (messageThread, isMobile, dates, priceColor, values1, epsColor, showBarChart, selectedScale) => {
  const dateFormatArr = formatDates(dates, selectedScale)

  return {
    responsive: true,
    maintainAspectRatio: false, 
    scales: {
      x: {
        ticks: {
          color: priceColor,
          maxTicksLimit: 10, 
          font: {
            size: 11
          },
          callback: function(value, index, values) {
            return dateFormatArr[value]
          }
        },
        grid: {
          display: false, // This will hide the grid lines for the X axis
        },
      },
      epsAxis: {
        type: 'linear',
        display: (values1 && values1.length > 0),
        position: 'left',
        color: epsColor,
        textColor: epsColor,
        beginAtZero: false,

        ticks: {
          color: epsColor,
          font: {
            size: 11
          },
          callback: function(value, index, values) {
            return formatLargeNumbers(Math.round(100*value)/100);
          }
        },
        grid: {
          display: false, // This will hide the grid lines for the X axis
        },
        afterDataLimits: axis => {
          if (showBarChart) {
            axis.max = axis.max * 2;
          }
        },
      },
      stockPriceAxis: {
        type: 'linear',
        display: true,
        position: 'right',
        color: priceColor,
        ticks: {
          color: priceColor,
          font: {
            size: 11
          },
          callback: function(value, index, values) {
            return '$' + value.toLocaleString();
          }
        },
        beginAtZero: false,
        grid: {
          display: false, // This will hide the grid lines for the X axis
        },
      },
    },
    plugins: plugins(messageThread, isMobile)
  }

}

const barOptions = (messageThread, isMobile, dates, priceColor, values1, epsColor, showBarChart, financialData, selectedScale) => {
  const totalDataPoints = financialData.length;
  const startIndex = Math.max(totalDataPoints - 40, 0);
  const dateFormatArr = formatDates(dates, selectedScale)

  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        labels: financialData.map(d => dates[d.x]),
        min: startIndex,
        max: totalDataPoints - 1,
        ticks: {
          color: priceColor,
          maxTicksLimit: 20, 
          font: {
            size: 11
          },
          callback: function(value, index, values) {
            return dateFormatArr[value]
          }
        },
        grid: {
          display: false, // Hide grid lines for x-axis
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'right',
        color: priceColor,
        ticks: {
          color: priceColor,
          font: {
            size: 11
          },
          callback: function(value, index, values) {
            return '$' + value.toLocaleString();
          }
        },
        beginAtZero: false,
        grid: {
          display: false, // This will hide the grid lines for the X axis
        },
      },
    },
    plugins: plugins(messageThread, isMobile)
  }
}

const formatDates = (dates, selectedScale) => {
  let dateFormatArr = []
  if (selectedScale == "1day") {
    dateFormatArr = dates.map((dateVal) => {
      const date = new Date(dateVal);
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return months[date.getMonth()] + " '" + date.getFullYear().toString().slice(2,4)
    })
  } else if (selectedScale == "5min" || selectedScale == "1min") {
    dateFormatArr = dates.map((dateVal) => {
      // DATE is already in PT, it just had the UTC there, so we first remove the UTC.
      //const date = new Date(dateVal.replace(" UTC", ""));
      const date = new Date(dateVal);
      const options = { 
        month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", 
        hour12: true, // Change to `true` for AM/PM format
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Ensures local timezone conversion
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    });
  }
  return dateFormatArr
}

const plugins = (messageThread, isMobile) => {
  return {
    title: {
      display: true,
      text: `${messageThread.tickerDescription[0]}`,
      font: {
        size: 14
      },
      position: 'top',
    },
    zoom: {
      pan: {
        enabled: !isMobile,
        mode: 'x',
      },
      zoom: {
        wheel: {
          enabled: !isMobile,
        },
        pinch: {
          enabled: !isMobile,
        },
        mode: 'x',
      },
    },
    legend: {
      labels: {
        // CAN MAKE CIRCLE: (Comment out to revert to square)
        usePointStyle: true, // This will use point style (circle) instead of box
        pointStyle: 'circle',
        boxWidth: 30,
        padding: 10, // Optional: if you want some padding
        // You can also set the font color and size here, if needed
        font: {
          size: 12, // Example size
          weight: 'bold', // Optional: if you want to set a font weight
        },
        // More styling can be done here as required
      },
      // Other legend options...
    }
  }
}

const ScaleSelectorDropdown = ({ selectedScale, setSelectedScale, availableChartScales }) => {
  const scales = availableChartScales
  return (
    <Box
      sx={{
        // zIndex: 1000,
        display: "flex",
        backgroundColor: isDarkMode ? "rgba(0, 0,#0, 0.8)" : "none",//rgba(155, 155, 155, 0.2)",
        padding: "4px",
        borderRadius: "4px",
      }}
    >
      <Select
        value={selectedScale}
        onChange={(e) => setSelectedScale(e.target.value)}
        variant="outlined"
        size="small"
        sx={{
          color: isDarkMode ? "white" : "rgba(95,95,95, 1.0)",
          backgroundColor: "none",
          fontSize: "14px",
          padding: "4px 6px",
          borderRadius: "4px",
          border: "2px solid transparent", // Apply general border first
          borderBottom: "2px solid #1976d2", // This will now take effect
          maxWidth: "100px",
          border: "2px solid transparent",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.3)",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1976d2",
          },
          "& .MuiSelect-select": {
            padding: "4px 6px",
            textTransform: "none",
          },
          "& .MuiSelect-icon": {
            color: isDarkMode ? "white" : "#1976d2", // Change caret color
          },

        }}
      >
        {scales.map((scale) => (
          <MenuItem
            key={scale.value}
            value={scale.value}
            sx={{
              color: selectedScale === scale.value ? "#1976d2" : "rgba(95,95,95, 1.0)",
              textTransform: "none",
              fontSize: "14px",
              padding: "4px 6px",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
          >
            {scale.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const ScaleSelector = ({ selectedScale, setSelectedScale, availableChartScales }) => {
  const scales = availableChartScales

  return (
    <Box
      sx={{
        position: "absolute",
        top: "46px",
        left: "30px",
        // zIndex: 1000,
        display: "flex",
        gap: "4px",
        backgroundColor: isDarkMode ? "#121212" : '#f5f5f5',
        padding: "4px",
        borderRadius: "4px",
      }}
    >
      {scales.map((scale) => (
        <Button
          key={scale.value}
          onClick={() => setSelectedScale(scale.value)}
          sx={{
            color: selectedScale === scale.value ? "#1976d2" : (isDarkMode ? "white" : "rgba(95,95,95, 1.0)"),
            border: "2px solid transparent", // This is so that border doesn't show up in the other areas
            borderBottom: selectedScale === scale.value ? "2px solid #1976d2" : "none",
            textTransform: "none",
            fontSize: "12px",
            padding: "4px 6px",
            minWidth: "30px",
            maxHeight: '24px',
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          {scale.label}
        </Button>
      ))}
    </Box>
  );
};

const ChartStyleSelector = ({ chartViewType, setChartViewType }) => {
  const isMobile = isMobileBrowser()
  const scales = [
    { label: "Line Chart", value: "line" },
    { label: "Bar Chart", value: "bar" },
  ]
  let desktopStyles = {}
  if (!isMobile) {
    desktopStyles = { position: "absolute", top: "0px", left: "-10px" }
  }
  return (
    <Box
      sx={{
        // zIndex: 3,
        display: "flex",
        gap: "10px",
        backgroundColor: isDarkMode ? "#121212" : '#f5f5f5',
        padding: "8px",
        borderRadius: "6px",
        ...desktopStyles
      }}
    >
      {scales.map((scale) => (
        <Button
          key={scale.value}
          onClick={() => {
            setChartViewType(scale.value)}}
          sx={{
            color: chartViewType === scale.value ? "#1976d2" : (isDarkMode ? "white" : "rgba(95,95,95, 1.0)"),
            border: "2px solid transparent", // This is so that border doesn't show up in the other areas
            borderBottom: chartViewType === scale.value ? "2px solid #1976d2" : "none",
            textTransform: "none",
            fontSize: "14px",
            padding: "6px 12px",
            minWidth: "40px",
            maxHeight: '34px',
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          {scale.label}
        </Button>
      ))}
    </Box>
  );
};

export { charting, ScaleSelector, ChartStyleSelector, ScaleSelectorDropdown }
