

const confirmEmailForUser = async (user_id, setUserSelectedList) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        admin {
          confirmEmailForUser(userId: ${user_id}) {
            id
            email
            firstName
            lastName

            loginMethod
            emailConfirmed

            accountType
            selectedCountry
            selectedCountryExchange
            stripeCustomerId

            createdAt

            activeSubscription
          }
        }
      }`
    })
  };

  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    const newUserItem = data.data.admin.confirmEmailForUser
    setUserSelectedList((prevList) => ({
      ...prevList, 
      [newUserItem.id]: newUserItem
    }));
  })
  .catch(error => console.error('Error fetching data:', error));
};

const getUserFeedbacks = async (user_id, setUserSelectedList) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        admin {
          getUserFeedbacks(userId: ${user_id}) {
            id
            userId
            feedback
            responseSentAt
            errorResolvedAt
            createdAt
          }
        }
      }`
    })
  };

  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    const newFeedbackItem = data.data.admin.getUserFeedbacks
    if (newFeedbackItem && newFeedbackItem.length > 0) {
      setUserSelectedList((prevList) => ({
        ...prevList, 
        [newFeedbackItem[0].userId]: newFeedbackItem // They'll all have same user id, so just grab first
      }));
    }
  })
  .catch(error => console.error('Error fetching data:', error));
};



const getAllUserFeedbacks = async (conditions, setUserSelectedList, setUserFeedbackData) => {
  const timeframe = conditions.timeframe
  const includeFeedbackResponded = conditions.includeFeedbackResponded
  const includeFeedbackResolved = conditions.includeFeedbackResolved

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        admin {
          getAllUserFeedbacks(timeframe: "${timeframe}", includeFeedbackResponded: ${includeFeedbackResponded}, includeFeedbackResolved: ${includeFeedbackResolved}) {
            user {
              id
              email
              firstName
              lastName

              loginMethod
              emailConfirmed

              accountType
              selectedCountry
              selectedCountryExchange
              stripeCustomerId

              createdAt

              activeSubscription
            }
            feedbacks {
              id
              userId
              feedback
              responseSentAt
              errorResolvedAt
              createdAt
            }
          }
        }
      }`
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    // Unfortunately the order of the user won't be preserved even though the backend returns the ordered data
    // Because we put it as an object.
    const userAndFeedbacks = data.data.admin.getAllUserFeedbacks
    const usersObject = userAndFeedbacks.reduce((acc, userAndFeedback) => {
      acc[userAndFeedback.user.id] = userAndFeedback.user;
      return acc;
    }, {}); 
    setUserSelectedList((prevList) => ({
      ...prevList, 
      ...usersObject
    }));  
    const feedbackObject = userAndFeedbacks.reduce((acc, userAndFeedback) => {
      acc[userAndFeedback.feedbacks[0].userId] = userAndFeedback.feedbacks;
      return acc;
    }, {}); 
    setUserFeedbackData((prevList) => ({
      ...prevList, 
      ...feedbackObject
    })); 

  })
  .catch(error => console.error('Error fetching data:', error));
};


const sendUserFeedbackEmailReply = async (user_id, user_feedback_id, curFeedbackReply, setLoadingEmailSend, setCurFeedbackReply, setUserFeedbackData, setErrors) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        admin {
          sendUserFeedbackEmailReply(userId: ${user_id}, userFeedbackId: ${user_feedback_id}, message: ${JSON.stringify(curFeedbackReply)}) {
            id
            userId
            feedback
            responseSentAt
            errorResolvedAt
            createdAt
          }
        }
      }`
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    if (data.errors) {
      console.error('Error fetching data:', data.errors)
      setErrors(data.errors.map((error) => error.message))
    }
    else if (data.data.admin.sendUserFeedbackEmailReply) {
      setLoadingEmailSend(false)
      setCurFeedbackReply('')
      const updatedFeedback = data.data.admin.sendUserFeedbackEmailReply;
      setUserFeedbackData((prevList) => {
        const newState = { ...prevList };
        if (newState[user_id]) {
          newState[user_id] = newState[user_id].map((feedback) =>
            feedback.id === user_feedback_id ? updatedFeedback : feedback
          );
        }
        return newState;
      });
    }
  })
  .catch(error => {
    console.error('Error fetching data:', error)
    // setErrors(error)
  });
};

const setUserFeedbackResolved = async (user_id, user_feedback_id, setUserFeedbackData, setErrors) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        admin {
          setUserFeedbackResolved(userFeedbackId: ${user_feedback_id}) {
            id
            userId
            feedback
            responseSentAt
            errorResolvedAt
            createdAt
          }
        }
      }`
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    if (data.errors) {
      console.error('Error fetching data:', data.errors)
      setErrors(data.errors.map((error) => error.message))
    } else if (data.data.admin.setUserFeedbackResolved) {
      const updatedFeedback = data.data.admin.setUserFeedbackResolved;
      setUserFeedbackData((prevList) => {
        const newState = { ...prevList };
        if (newState[user_id]) {
          newState[user_id] = newState[user_id].map((feedback) =>
            feedback.id === user_feedback_id ? updatedFeedback : feedback
          );
        }
        return newState;
      });
    }
  })
  .catch(error => {
    console.error('Error fetching data:', error)
    // setErrors(error)
  });
};


const sendUserUnverifiedEmailReply = async (user_id, curUnverifiedReply, setLoadingEmailSend, setCurUnverifiedReply, setCurSentUnverifiedReply, setErrors) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: `
      {
        admin {
          sendUserUnverifiedEmailReply(userId: ${user_id}, message: ${JSON.stringify(curUnverifiedReply)}) {
            status
          }
        }
      }`
    })
  };
  fetch('/graphql', requestOptions)
  .then(response => response.json())
  .then(data => {
    if (data.errors) {
      console.error('Error fetching data:', data.errors)
      setErrors(data.errors.map((error) => error.message))
    }
    else if (data.data.admin.sendUserUnverifiedEmailReply) {
      setLoadingEmailSend(false)
      setCurUnverifiedReply('')
      setCurSentUnverifiedReply(true)
    }
  })
  .catch(error => {
    console.error('Error fetching data:', error)
    // setErrors(error)
  });
};

export { confirmEmailForUser, getUserFeedbacks, getAllUserFeedbacks, sendUserFeedbackEmailReply, setUserFeedbackResolved, sendUserUnverifiedEmailReply }
