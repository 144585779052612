import { createConsumer } from "@rails/actioncable";
import { websocketUrl } from "../utils/constants";
import { isProduction } from "../utils/constants";

// Define a function that establishes a WebSocket connection
export const connectPriceSocket = (current_session_id, subscriptionRef, setAnimatedItems, latestTickerPrices, setLatestTickerPrices, setDisplayUpdatedInfo) => {

  const consumer = createConsumer(websocketUrl);
  const subscription = consumer.subscriptions.create(
  { channel: "WebsocketMessageChannel", current_session_id: current_session_id },
  {
    connected() {
    console.log("LIVE-CHART: Connected to websocket channel");
    },
    disconnected() {
      console.log("Disconnected from websocket channel");
      // For example, you can call connectWebSocket() again to reconnect.
      // console.log("Attempting to reconnect...")
      // connectWebSocket(); // pontentially implemenet backoff strategy!!! (reconnect dealys)
    },
    rejected() {
      console.error("LIVE-CHART: Connection rejected!!");
    },
    received(data) {
      console.log("Receiving Data:", data)
       
      if (!data["websocket-data"]) return
      const websocketData = data["websocket-data"]
      console.log("websocketData?", websocketData)
      
      const priceData = websocketData["prices-values"]//["prices"]
      const pctChangeData = websocketData["pct-change"]
      const strPriceData = websocketData["prices"]
      const formattedUpdatedAtData = websocketData["updated-at"]
      if (!priceData) return // Ensure that this is the data with websocket-data.

      const newPrice = priceData[Object.keys(priceData)[0]] // Only one item.
      const newStrPrice = strPriceData[Object.keys(strPriceData)[0]] // Only one item.
      const formattedUpdatedAt = formattedUpdatedAtData[Object.keys(formattedUpdatedAtData)[0]] // Only one item.
      const formattedPctChange = pctChangeData[Object.keys(pctChangeData)[0]] // Only one item.
      setLatestTickerPrices((prevData) => {
        const lastVal = prevData[prevData.length-1]
        // const diff = parseFloat(newPrice)/lastVal.close - 1
        if (true) {//(isProduction) {
          lastVal.close = parseFloat(newPrice)
        } else { // in development, add bigger changes!
          lastVal.close = parseFloat(newPrice)*(1+Math.random()/50)
        }
        
        // Adjust the high and low as needed.
        lastVal.high = Math.max(lastVal.close, lastVal.high)
        lastVal.low = Math.min(lastVal.close, lastVal.low)

        const newData = prevData.slice(0, prevData.length-1).concat([lastVal])
        setDisplayUpdatedInfo(prevData => {
          prevData.currentPrice = newStrPrice
          prevData.currentPriceUpdatedAt = formattedUpdatedAt
          prevData.pricePctChange1d = parseFloat(formattedPctChange)/100 // because in desplay it reparses and multiples again
          return prevData
        })

        return newData
      })

    }
  });
  subscriptionRef.current = subscription
};


