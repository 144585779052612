import { useNavigate } from "react-router-dom";
import { Box, Link, MenuItem } from "@mui/material";
import { useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect } from "react";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import "../../stylesheets/index.css";
import AboutIconBlue from "../../javascript/images/AboutIconBlue.png";
import { isDarkMode, themeColors } from "../utils/constants";
import { ShareModal } from "../utils/share";
import "../../stylesheets/navbar.css";
import MenuDotsIconLightMode from "../../javascript/images/MenuDotsIconLightMode.png";
import MenuDotsIconDarkMode from "../../javascript/images/MenuDotsIconDarkMode.png";
import WatchlistModal from "./WatchlistModal";
import WatchlistLight from "../../javascript/images/WatchlistLight.png";
import WatchlistDark from "../../javascript/images/WatchlistDark.png";
import { getWatchlistData } from "../api/user";
import { isMobileBrowser } from "../utils/user_agent";

const AccountMenu = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { appProps } = props;

  const { userData, setUserData } = appProps;

  const handleLogout = (event) => {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    fetch("/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error_message) {
          appProps.setErrors(data.error_message);
        } else {
          navigate("/chat");
          appProps.clearStateData();
        }
      })
      .catch((error) => {
        console.log("api errors:", error);
        // setErrors(error[0])
      });
  };
  let isLoggedIn = !!appProps.userData.user_id;
  let loggedInClassName = isLoggedIn ? "logged-in" : "logged-out";
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title="Account settings">
          <Box className={loggedInClassName}>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 5 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              aria-label={"Account Settings Menu"}
            >
              {isLoggedIn && (
                <Box
                  sx={{
                    width: 26,
                    height: 26,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%", // Ensures it's circular
                    border: isDarkMode
                      ? "1.5px solid #F5F5F5"
                      : "1.5px solid #121212", // Update for light mode
                    background: isDarkMode ? "#121212" : "#F5F9FE", // Background based on mode
                    color: isDarkMode ? "#F5F5F5" : "#121212", // Text color contrast
                    fontSize: "16px",
                  }}
                >
                  {appProps.userData.user_initial}
                </Box>
              )}
              {!isLoggedIn && (
                <img
                  src={
                    isDarkMode ? MenuDotsIconDarkMode : MenuDotsIconLightMode
                  }
                  style={{ marginTop: "6px", maxHeight: "100%", width: "18px" }}
                />
              )}
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            backgroundColor: themeColors.chatBoxColor,
            color: themeColors.color,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              // bgcolor: 'background.paper',
              bgcolor: themeColors.chatBoxColor,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isLoggedIn && (
          <Box>
            {appProps.userData && appProps.userData.is_admin_user && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("/puerta-web");
                }}
              >
                Puerta Web
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/account");
              }}
            >
              Account
            </MenuItem>
            <MenuItem
              onClick={() => {
                const isConfirmed = window.confirm(
                  "Are you sure you want to clear all messages?"
                );
                if (isConfirmed) {
                  handleClose();
                  appProps.requestClearChatMessageHistory();
                }
              }}
            >
              Clear Messages
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                // navigate('/faqs')
                window.open("https://www.inciteai.com/learn-more", "_blank");
              }}
            >
              Learn More
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose();
                window.open("https://inciteai.com");
              }}
            >
              Website
            </MenuItem>

            {/* <MenuItem onClick={() => {
          handleClose()
          window.open("https://inciteai.com/about")
        }}>
          About Incite
        </MenuItem> */}
            {/* <MenuItem onClick={() => {
          handleClose()
          appProps.requestClearChatMessageHistory()
        }}>
          Clear History
        </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/terms-of-use");
              }}
            >
              Terms of Use
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/feedback");
              }}
            >
              Feedback
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                window.open("https://www.inciteai.com/contact", "_blank");
              }}
            >
              Contact
            </MenuItem>
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </Box>
        )}

        {!isLoggedIn && (
          <Box>
            <MenuItem
              onClick={() => {
                handleClose();
                setUserData({ ...userData, uuidHasAccount: true });
                navigate("/register");
              }}
            >
              Log In
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose();
                // navigate('/faqs')
                window.open("https://www.inciteai.com/learn-more", "_blank");
              }}
            >
              Learn More
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose();
                window.open("https://inciteai.com");
              }}
            >
              Website
            </MenuItem>
            {/* <MenuItem onClick={() => {
          handleClose()
          window.open("https://inciteai.com/about")
        }}>
          About Incite
        </MenuItem> */}
            {/* <MenuItem onClick={() => {
          handleClose()
          appProps.requestClearChatMessageHistory()
        }}>
          Clear History
        </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleClose();
                window.open(
                  "https://inciteai.com/terms-and-conditions",
                  "_blank"
                );
              }}
            >
              Terms and Conditions
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                window.open("https://inciteai.com/privacy-policy", "_blank");
              }}
            >
              Privacy Policy
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/feedback");
              }}
            >
              Feedback
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                window.open("https://www.inciteai.com/contact", "_blank");
              }}
            >
              Contact
            </MenuItem>
          </Box>
        )}
      </Menu>
    </React.Fragment>
  );
};

const Navbar = (props) => {
  const {
    disallowNav,
    allowAbout,
    appProps,
    showSubscriptionStatus,
    useLightMode,
    headerDescription,
    largeIncite,
    onQuestionSubmit,
  } = props;
  const {
    watchlistData,
    setWatchlistData,
    setShowSignUpModal,
    lastRequestedUpdateWatchlist,
    setLastRequestedUpdateWatchlist,
    showWatchlistBlueDot,
    setShowWatchlistBlueDot,
    showWatchlistFlicker,
  } = appProps;
  // let navigate // must define only when nav is allowed & is defined within routes
  // if (!disallowNav && allowAbout) {
  const navigate = useNavigate();
  const isMobile = isMobileBrowser();
  // }
  const {
    userData,
    setUserData,
    isRefreshing,
    setIsRefreshing,
    hasErroredWatchlist,
    setHasErroredWatchlist,
    setShowPaywallModal,
    setPaywallMessage,
  } = appProps;

  const { setShowChatShare } = appProps;

  const useIsDarkMode = !useLightMode == true && isDarkMode;

  let isLoggedIn = !!appProps.userData.user_id;

  let classNavbarText = useIsDarkMode
    ? "navbar-text-styles dark"
    : "navbar-text-styles";
  const [modalVisible, setModalVisible] = useState(false);
  const [ showSignInButton, setShowSignInButton ] = useState(false);
  const [watchlistVisible, setWatchlistVisible] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    // if (!isMobile) {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setWatchlistVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // }
    // }, [watchlistVisible, isMobile]);
  }, [watchlistVisible]);

  useEffect(() => {
    if (isLoggedIn) {
      getWatchlistData(setWatchlistData);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (userData && userData.user_account_info && Object.keys(userData.user_account_info).length > 0) {
      setShowSignInButton(false)
    } else {
      setShowSignInButton(true)
    }
  }, [userData])

  return(  
    <Box className="navbar-container" sx={{ backgroundColor: themeColors.navColor }}>
      <Box className="navbar-hamburger-container">
        <Box className="navbar-hamburger">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "0px",
            }}
          >
            {/* { (!disallowNav && allowAbout && !isLoggedIn) && */}
            {false && (
              <Box
                className={classNavbarText}
                sx={{
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                About
                <IconButton
                  onClick={() => {
                    window.open("https://inciteai.com/about");
                  }}
                  size="small"
                  sx={{ ml: 0 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  aria-label="About" // This is the accessible name for the button
                >
                  <img
                    src={AboutIconBlue}
                    alt=""
                    style={{ maxHeight: "100%", width: "12px" }}
                  />
                </IconButton>
              </Box>
            )}

            {/* PRE-LAUNCH, ONLY ADMIN CAN SEE!! */}
            {window.location.pathname == "/chat" && (
              // { (window.location.pathname == '/chat') && appProps && appProps.userData && appProps.userData.is_admin_user &&
              <Box
                sx={{
                  "&:hover": { cursor: "pointer" },
                  color: themeColors.linkColor,
                  fontSize: "16px",
                }}
                onClick={() => {
                  if (isLoggedIn) {
                    setShowWatchlistBlueDot(false);
                    setWatchlistVisible(!watchlistVisible);
                  } else {
                    // navigate('/register')
                    setShowSignUpModal(true);
                  }
                }}
              >
                <img
                  className={showWatchlistFlicker ? "fade-animation" : "unset"}
                  src={isDarkMode ? WatchlistDark : WatchlistLight}
                  style={{
                    marginLeft: "20px",
                    marginTop: "0px",
                    maxHeight: "100%",
                    width: "28px",
                  }}
                />
                {showWatchlistBlueDot && (
                  <Box
                    className={
                      showWatchlistFlicker ? "fade-animation" : "unset"
                    }
                    sx={{
                      backgroundColor: "rgba(78, 120, 241, 1.0)",
                      position: "absolute",
                      left: "35px",
                      top: "17px",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </Box>
            )}

            {allowAbout && isLoggedIn && (
              <Box
                className={classNavbarText}
                sx={{
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ShareModal
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                />
                {/* <ShareButton setModalVisible={setModalVisible} setShowChatShare={setShowChatShare}/> */}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {watchlistVisible && (
          <WatchlistModal
            setWatchlistVisible={setWatchlistVisible}
            modalRef={modalRef}
            watchlistData={watchlistData}
            setWatchlistData={setWatchlistData}
            onQuestionSubmit={onQuestionSubmit}
            lastRequestedUpdateWatchlist={lastRequestedUpdateWatchlist}
            setLastRequestedUpdateWatchlist={setLastRequestedUpdateWatchlist}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
            hasErroredWatchlist={hasErroredWatchlist}
            setHasErroredWatchlist={setHasErroredWatchlist}
            userData={userData}
            setPaywallMessage={setPaywallMessage}
            setShowPaywallModal={setShowPaywallModal}
          />
        )}
        { headerDescription ?
          <h1 className="base-text main-header-text" style={{color: themeColors.color}}>
            {headerDescription}
          </h1>
        :
          <Box className="base-text" sx={{fontSize: isMobile ? '16px':'24px', marginTop: '-5px', fontWeight: 500, color: isDarkMode ? 'white':'black'}}>Incite</Box>
        }
      </Box> 
      <Box className="navbar-hamburger-container" sx={{
        width: showSignInButton ? 'unset':'50px',
        marginRight: (showSignInButton && isMobile) ? '16px':'32px',
        }}>
        <Box className="navbar-hamburger">
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '0px',
          }}>
            { headerDescription == "Sign up" 
            ?
              <Box sx={{ width: '80px', fontSize: '11px'}} className="base-text sign-text" >
                <Link 
                  sx={{'&:hover': {cursor: 'pointer'}, color: themeColors.linkColor, fontSize: '16px'}} 
                  onClick={() => {
                    setUserData({ ...userData, uuidHasAccount: true });
                    navigate("/register");
                  }}
                >
                  Sign In
                </Link>
              </Box>
            :
              <>
                { (!disallowNav) &&
                  <>
                    {(!showSignInButton) ?
                      <AccountMenu appProps={props.appProps} />
                    :
                      <SignInButton appProps={props.appProps} isMobile={isMobile}/> 
                    }
                  </>
                }
              </>
            }
          </Box>
        </Box>
      </Box>
      <AccountButton appProps={props.appProps} />
    </Box>
  );
};

const SignInButton = (props) => {
  const { isMobile } = props
  const navigate = useNavigate()
  return (
      <Box sx={{
        // marginLeft: isMobile ? '-40px':'-50px',
        // marginLeft: isMobile ? '-26px':'-50px',
        marginLeft: isMobile ? '-10px':'-50px',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        height: isMobile ? '26px':'38px', width: isMobile ? '72px':'98px', borderRadius: '22px',
        color: 'white',//isDarkMode ? '#f5f5f5' : '#121212',
        fontSize: isMobile ? '12px':'unset',
        fontWeight: 600,
        backgroundColor: '#5390E6',//isDarkMode ? '#252525':'#E8E8E8',
        paddingBottom: isMobile ? '3px':'unset',
        '&:hover': {cursor: 'pointer'}
      }} onClick={() => {
        navigate('/register')
      }} className={"base-text"}
      >
        Sign In
      </Box>
  )
}

const AccountButton = (props) => {
  const { appProps } = props;
  return (
    <div className="navbar-account-container">
      <p
        className="navbar-text-styles"
        onClick={() => {
          appProps.setAccountModalOpen(true);
        }}
      >
        Account
      </p>
    </div>
  );
};

export { Navbar };
