import { Box, Button } from '@mui/material';
import { Navbar } from "../components/Navbar";
import { isDarkMode, themeColors } from '../utils/constants';
import Back from '../components/Reusable/Back';
import '../../stylesheets/index.css'
import { useState, useEffect } from 'react';
import feedbackIncite from '../api/feedback'
import CircularProgress from '@mui/material/CircularProgress';

const FeedbackPage = (props) => {
  const {
    appProps
  } = props

  const [question, setQuestion] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let classPlaceholderInput = isDarkMode ?
    "feedback-input-dark"
    :
    "feedback-input"

  const submitQuestion = () => {
    if (isLoading || isSubmitted || question == '') { 
      return
    }
    setIsLoading(true); 
    
    feedbackIncite(question)
    .then((resp) => {
      if (resp == "ERROR") {
        setErrorMessage("There was an unknown error; please try again later or contact help@inciteai.com")
      } else {
        console.log("Submitted?")
        setQuestion('')
        setIsSubmitted(true)
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (isSubmitted && question != '') {
      setIsSubmitted(false)
    }
  }, [question])

  return (
    <Box sx={{width: '100vw', height: '100vh', backgroundColor: themeColors.backgroundColor}}>
      <Box sx={{position: 'fixed', left: '0px', top: '0px', width: '100%'}}>
        <Navbar appProps={appProps} headerDescription={'Feedback'}/>
        <Back value={false}/>
      </Box>
      <Box sx={{width: '100%', height: '100%', display: 'flex'}}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          marginTop: '100px',
          width: '80%',
          maxWidth: '600px',
          alignItems: 'center',
          paddingBottom: '30px',
        }}>
          <Box className="feedback-text"
           sx={{color: themeColors.color, width: '100%'}}
           >
            <p className="feedback-text" style={{color: themeColors.primaryText, fontWeight: 500}}>We value your feedback at Incite.
            <br/><br/>
            Please share your thoughts and suggestions. Your input helps us improve and provide a better experience.
            <br/><br/>
            Thank you for your contribution!
            </p>
          </Box>

          <Box>
            <p className="feedback-text" style={{color: 'red', fontWeight: 500}}>
              {errorMessage}
            </p>
          </Box>
          
          <Box sx={{marginTop: '20px', width: '100%', position: 'relative', 
            height: '400px', maxHeight: '35vh',
            }}>
            <textarea
              style={{
                height: '100%',
                position: 'absolute',
                left: '0px',
                right: '0px',
          
                outline: 'none', 
                boxShadow: 'none', 
                transition: 'border 0.2s ease-in-out', 
              }}
              className={classPlaceholderInput}
              value={question}
              onChange={e => setQuestion(e.target.value)}
              placeholder={'Message..'}
  
            />
          </Box>

          <Button variant="contained" className="i_understand_button" sx={{
            marginTop: '40px',
            textTransform: 'none',
            borderRadius: '24px',
            background: themeColors.buttonPrimaryColor,
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
            width: '300px',
            maxWidth: '100%',
            transition: 'opacity 0.2s ease-in-out',
              '&:hover': {
                background: themeColors.buttonPrimaryColor, 
                opacity: 0.7,
              },
            
          }} 
          disabled={isSubmitted}
          onClick={() => 
            submitQuestion()
          }>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />  // Show spinner when loading
            ) : (
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontWeight: 500, alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '2px',
              color: themeColors.primaryText,
              
            }}>
              {isSubmitted ? 
              'Submitted!'
              :
              'Share Feedback'
              }
            </Box>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
export default FeedbackPage
