import { Box } from '@mui/material';
import PaywallJoinThousands from '../../javascript/images/PaywallJoinThousandsCompressed.png'
import JoinThousandsText from '../../javascript/images/JoinThousandsText.png'
import MarketProLogo from '../../javascript/images/MarketProLogo.png'
import "../../stylesheets/chat_page.css"
import { useState, useEffect } from 'react';
import PaywallButton from './Mui/PaywallButton';
import { useNavigate } from 'react-router-dom';

const PaywallModal = ({ userData, setUserData, setShowPaywallModal, paywallMessage, setShowSignUpModal, setShowPaywallAfter}) => {

  const navigate = useNavigate()

  const [opacity, setOpacity] = useState(0)
  const handleClose = () => {
    setShowPaywallModal(false); // Hide modal
    // setShowPaywallAfter(false); // won't default to showing after sign up modal close.
  };

  useEffect(() => {
    setOpacity(1.0)
  }, [])

  const onClickAction = () => {
    if (userData.user_id) { // if we have the user id...
      navigate('/plan-selection')
    } else {
      setShowPaywallModal(false)
      setShowSignUpModal(true)
    }
  }

  return (
    <div className="modal modal-paywall" style={{
      opacity: opacity,
      transition: 'opacity 1.0s',
      }}>
      <div className="paywall-modal-content" style={{}}>
        
        <Box className="base-text" sx={{
          fontSize: '15px', color: '#2971D7', fontWeight: '500', 
          position: 'absolute', top: '20px', left: '20px', '&:hover': {cursor: 'pointer'}}} onClick={handleClose}
        >
          Close
        </Box>

        <Box className="base-text" sx={{gap: '0px', marginBottom: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight: '500', fontSize: '36px'}}>
          <img src={MarketProLogo} style={{width: '32px'}} className="logo-img" alt="IncitePlus Logo" />
          <Box className="header-paywall-modal" sx={{fontWeight: '600', color: '#1D1D1F'}}>
            Incite AI
          </Box>
        </Box>

        <Box className="base-text" sx={{margin: '0px auto 0px auto', fontWeight: '500', fontSize: '17px', lineHeight: '150%',}}>
          Real Time Intelligence
        </Box>
        <Box className="base-text" sx={{margin: '20px auto 26px auto', fontWeight: '500', fontSize: '16px', maxWidth: '85%', lineHeight: '150%', color: '#FD0707'}}>
          {paywallMessage}
        </Box>

        <Box sx={{width: '260px', margin: '0px auto', maxWidth: '86%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <PaywallButton onClick={() => onClickAction()}>
            <Box sx={{width: '100%', ontWeight: '500', fontSize: '14px', margin: '6px auto',}}>{'Learn More & Upgrade'}</Box>
          </PaywallButton>
        </Box>

        {/* <Box className="base-text" sx={{margin: '8px auto 0px auto', fontWeight: '400', fontSize: '14px', lineHeight: '150%',}}>
          Starting at $3.99
        </Box> */}

        {/* <Box sx={{margin: '26px auto 0px auto', width: '270px'}}>
          <img src={JoinThousandsText} style={{maxWidth: '100%'}}/>
        </Box> */}

        <Box sx={{marginTop: '22px', width: '100%'}}>
          <img src={PaywallJoinThousands} style={{maxWidth: '100%'}}/>
        </Box>
      

        {/* <Box className="base-text" sx={{marginTop: '18px', fontWeight: '500', fontSize: '22px', lineHeight: '133%'}}>
          A world of world-class<br/>intelligence.
        </Box> */}

      </div>
    </div>
  )
}

export default PaywallModal
