import { Box, Link } from '@mui/material';
import PaywallBackWhite from '../../javascript/images/PaywallBackWhite.svg'
import PaywallSelectCheck from '../../javascript/images/PaywallSelectCheck.png'
import Check from '../../javascript/images/Check.png'
import '../../stylesheets/plan-selection.css'
import { useState, useEffect } from 'react';
import PaywallButton from '../components/Mui/PaywallButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobileBrowser } from '../utils/user_agent';

const PlanSelection = () => {
  const navigate = useNavigate();
  const [planSelected, setPlanSelected] = useState('monthly');

  const [offerValue, setOfferValue] = useState(null)
  const [offerPresent, setOfferPresent] = useState(false)

  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const offer = params.get('offer');
    setOfferValue(offer)
    if (offer === 'monthly-market-pro') {
      setOfferPresent(true)
    }
  }, [location.search])

  let continueUrl = `/create-checkout-session?plan=${planSelected}`
  if (offerValue != null) {// && planSelected == 'monthly') {
    // ^ We still want to pass value to annual plan selected as well. (So if nav back, will retain it)
    continueUrl += `&offer=${offerValue}`
  }

  const isMobile = isMobileBrowser()

  return (
    <Box className="background" sx={{margin: 0, padding: 0}} >
      <Box className="main-container" sx={{backgroundColor: 'black', paddingLeft: 0, paddingRight: 0,}}>
        <Box className="back-button" onClick={() => navigate('/chat')}>
          <img src={PaywallBackWhite} style={{height: '16px', width: '16px'}} alt="Back" /> 
        </Box>
        <Box className="base-text description" sx={{color: '#fff'}}>
          Upgrade your plan
        </Box>

        <Box className="base-text selection-area" sx={{
          marginTop: '30px',
          marginBottom: '12px',
          borderRadius: '12px',
          border: '1px solid rgba(60,60,60)',
          color: 'white',
          fontSize: isMobile ? '11px':'12px',
          display: 'flex', flexDirection: 'column', 
          gap: isMobile ? '6px':'16px', 
          padding: isMobile ? '14px':'18px',
          textAlign: 'left',
          lineHeight: '167%'
        }}>
          <Box sx={{textAlign: 'center', marginBottom: '6px', fontWeight: '600', fontSize: '13px'}}>Level up productivity and decisions with expanded access</Box>
          <Box><img src={Check} style={{maxWidth: '12px', marginRight: '7px'}} alt="Check"/>Everything in Free</Box>
          <Box><img src={Check} style={{maxWidth: '12px', marginRight: '7px'}} alt="Check"/>Extended limits on messaging, advanced data analysis and real-time visuals.</Box>
          <Box><img src={Check} style={{maxWidth: '12px', marginRight: '7px'}} alt="Check"/>Access to premium data sources.</Box>
          <Box><img src={Check} style={{maxWidth: '12px', marginRight: '7px'}} alt="Check"/>Access to semi-autonomous watchlist.</Box>
          <Box><img src={Check} style={{maxWidth: '12px', marginRight: '7px'}} alt="Check"/>Opportunities to test new features</Box>
        </Box>
      </Box>

      <Box className="base-text selection-area">
        <SelectionContainer offerPresent={false} plan={"free"} planSelected={planSelected} setPlanSelected={setPlanSelected} isMobile={isMobile} />
      </Box>
      <Box className="base-text selection-area">
        <SelectionContainer offerPresent={offerPresent} plan={"monthly"} planSelected={planSelected} setPlanSelected={setPlanSelected} isMobile={isMobile} />
      </Box>
      <Box className="base-text selection-area">
        <SelectionContainer offerPresent={offerPresent} plan={"yearly"} planSelected={planSelected} setPlanSelected={setPlanSelected} isMobile={isMobile} />
      </Box>

      <form action={continueUrl} method="POST">
        <Box className="button-container">
          <PaywallButton type="submit">
            <Box className="button-text" sx={{fontSize: '16px'}}>Continue</Box>
          </PaywallButton>
        </Box>
      </form>

      <Box className="footer">
        By subscribing you agree to our <Link className="link" href="https://inciteai.com/terms-and-conditions" target="_blank">Terms of Service.</Link> Subscriptions auto-renew until cancelled, as described in the Terms. Cancel anytime. A verified email is required to subscribe. If you’ve subscribed on another platform, manage your subscription through that platform. Payments are non-refundable.
      </Box>
    </Box>
  );
}

const SelectionContainer = ({ offerPresent, plan, planSelected, setPlanSelected, isMobile }) => {
  const isSelected = planSelected === plan;
  const monthlyOptions = {
    duration: 'Pro Monthly',
    price: offerPresent ? '$7.99/mo.' : '$15.99/mo.',
    yearEquivalent: '$119.88'
  };
  const annualOptions = {
    duration: 'Pro Annual',
    price: '$13.33/mo.',
    yearEquivalent: '$119.88'
  };

  const freeOptions = {
    duration: 'Basic',
    price: 'Free',
  };

  let options
  let selectColor
  if (plan === "monthly") {
    options = monthlyOptions
    selectColor = 'black'
  } else if (plan == "yearly") {
    options = annualOptions
    selectColor = 'black'
  } else {
    options = freeOptions
    selectColor = 'black'
  }

  return (
    <Box className={`selection-container ${isSelected ? 'selected' : ''}`} sx={{
      //height: isMobile ? (plan=="free" ? '70px':'86px'):'108px',
      height: isMobile ? 'unset':'108px',
      padding: isMobile ? '8px':'18px',
      
      width: '100%',
      backgroundColor: 'rgba(232,232,232)',
      
      // override the highlighting options for free plan (not selectable)
      '&:hover': (plan=="free") ? {
        cursor: 'default', boxShadow: 'none',
      }:{}
    }}
      onClick={() => {
        if (plan != "free") {
          setPlanSelected(plan)
        }
      }}
    >
      {isSelected && (
        <Box className="check-mark">
          <img src={PaywallSelectCheck} style={{maxWidth: '16px'}} alt="Check" />
        </Box>
      )}
      <Box className="base-text option-duration" >{options.duration} <span style={{fontSize: '11px', opacity: 0.50}}>{(offerPresent && options.duration == 'Monthly') ? '(With 50% Discount, first 3 months)':''}</span></Box>
      <Box className="base-text option-price" style={{ color: selectColor }}>{options.price}</Box>
      <Box className="base-text option-detail" sx={{
        marginBottom: 0, paddingBottom: 0,
      }} >
        {plan === "monthly" &&
          "Billed monthly, Unlimited messages and features." 
        }
        {plan === "yearly" &&
          <>
          { offerPresent ?
            <>
              Billed Annually.
            </>
          :
            <>
              <span style={{color: '#2971D7'}}>Save $31.89/yr.</span> with Annual Plan. Unlimited messages and features.
            </>
          }
          </>
        }
        {plan === "free" &&
          "Limited features." 
        }
      </Box>
    </Box>
  );
}

export default PlanSelection;
