import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { isProduction } from '../utils/constants'
import { isDarkMode, themeColors } from "../utils/constants";
import { useNavigate } from 'react-router-dom';

const SignIn = (props) => {
  const {
    // closeModal,
    setGoToChat,
    appProps
  } = props

  const navigate = useNavigate()

  const { userData, setUserData, setShowSignUpModal } = appProps

  const [signInParams, setSignInParams] = useState({
    email: isProduction ? "" : "andrew@metpro.co",
    password: isProduction ? "" : "12345678"
  })
  const [errors, setErrors] = useState([])

  const handleSubmit = (event) => {
    event.preventDefault()
    let user = {
      email: signInParams.email,
      password: signInParams.password,
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user) // Don't need to pass as {user: user} since not looking for params[:user]
    } 
    fetch('/login', requestOptions)
      .then(response => {
        return (response.json())
      })
      .then(data => {
        if (data.error_message) {
          setErrors(data.error_message)
        }
        if (data.user_id) {
          setShowSignUpModal(false)
          if (data.is_email_confirmed) {
            navigate('/chat')
          } else {
            navigate('/confirm-email')
          }
          setUserData({...userData, ...data, requireSignIn: false, }) //set back to false if they were required to sign in immediately (same IP address user)
        }
      })
      .catch(error => {
        console.log('api errors:', error)
        setErrors(error[0])
      })
  }

  const handleChange = (event) => {
    const {name, value} = event.target
    setSignInParams(signInParams => ({
      ...signInParams,
      [name]: value,
    }))
  }

  return (
  <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Box sx={{width: '94%', maxWidth: '350px'}}>
      <form autoComplete="off" onSubmit={handleSubmit} >
        <Box sx={{color: 'rgb(255,0,0,.6)'}}>
          <ul>{errors.map((error) => {
            return <li key={error}>{error}</li>
            })}
          </ul>
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <TextField 
            sx={{
              marginBottom: '15px',
              '& .MuiInputBase-input': { // This targets the input itself
                color: themeColors.color,
              },
              '& .MuiFormLabel-root': { // This targets the label
                color: themeColors.color,
              },
              '& .MuiOutlinedInput-root': { // This targets the root of the input and the border color
                color: themeColors.color,
                '& fieldset': {
                  borderColor: themeColors.color,
                },
                '&:hover fieldset': {
                  borderColor: themeColors.color,
                },
                '&.Mui-focused fieldset': {
                  borderColor: themeColors.color,
                },
              },
            }}
            inputProps={{ required: true}} 
            // required={true} 
            InputLabelProps={{
              style: {
                fontFamily: 'Montserrat', 
                fontWeight: '400',
                color: themeColors.color,//themeColors.color,
                // marginRight: '20px',
                // paddingRight: '20px',
              }}} 
            type="email" 
            value={signInParams.email}
            onChange={handleChange}
            autoComplete="off"
            name="email"
            label="Email"/>
          {/* <TextField 
            sx={{marginBottom: '15px', 
            color: themeColors.color,
            textColor: themeColors.color,
            borderColor: 'blue',
            }}
            inputProps={{ minLength: 8, required: true }}
            InputLabelProps={{
              style: {fontFamily: 'Montserrat', fontWeight: '400',
              color: themeColors.color,
              // marginRight: '20px',
              // paddingRight: '20px',
            }}} 
            type="password" 
            value={signInParams.password}
            onChange={handleChange}
            autoComplete="off"
            name="password"
            label="Password"/> */}
          <TextField
            sx={{
              marginBottom: '15px',
              '& .MuiInputBase-input': { // This targets the input itself
                color: themeColors.color,
              },
              '& .MuiFormLabel-root': { // This targets the label
                color: themeColors.color,
              },
              '& .MuiOutlinedInput-root': { // This targets the root of the input and the border color
                color: themeColors.color,
                '& fieldset': {
                  borderColor: themeColors.color,
                },
                '&:hover fieldset': {
                  borderColor: themeColors.color,
                },
                '&.Mui-focused fieldset': {
                  borderColor: themeColors.color,
                },
              },
            }}
            inputProps={{ minLength: 8, required: true }}
            InputLabelProps={{
              style: {
                fontFamily: 'Montserrat', 
                fontWeight: '400',
                color: themeColors.color,
              },
            }}
            type="password"
            value={signInParams.password}
            onChange={handleChange}
            autoComplete="off"
            name="password"
            label="Password"
          />
          
          {/* <Box sx={{backgroundColor: 'green', marginTop: '0px', paddingTop: '0px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
              <Box sx={{width: '100%', textAlign: 'center', display: 'flex',  justifyContent: 'center',}}>
          <Box sx={{marginTop: '10px',}}>
            <Button variant="contained" className="i_understand_button" sx={{
              textTransform: 'none',
              borderRadius: '24px',
              background: themeColors.buttonPrimaryColor,
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
              marginTop: '0px',
              transition: 'opacity 0.2s ease-in-out',
              '&:hover': {
                background: themeColors.buttonPrimaryColor, 
                opacity: 0.7,
              },
              
            }} 
            type="submit"
            >
            <Box className="base-text i_understand_text" sx={{
              display: 'flex',
              fontWeight: '500', 
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '2px',
              color: themeColors.primaryText,
              fontSize: '20px',
            }}>
              Sign In
            </Box>
            </Button>
          </Box>
        </Box>
          {/* </Box> */}
        </Box>
      </form>
    </Box>
  </Box>
  )
}

export default SignIn